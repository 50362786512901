// React
import React, { useEffect, useState } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// react-copy-to-clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

// Functions
import getPubKeyUrl from "user/getPubKeyUrl";
import addPubKeyUrl from "user/addPubKeyUrl";

// A ---------------------------------------------------------------------- M

const IAMCard = (props) => {
  const { user, seedflow, pubKey, canSign } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [pbkurl, setPbkurl] = useState("");
  const [copied, setCopied] = useState(false);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const pbkurl = await getPubKeyUrl(user.uid);
      setPbkurl(pbkurl);
    };

    fetch();
  }, [user]);

  const handleCopy = () => {
    setCopied(true);
  };

  const handleSave = async () => {
    if (pbkurl) await addPubKeyUrl(user.uid, pbkurl);
    setEditable(false);
  };

  const handleEdit = () => {
    setEditable(true);
  };

  return (
    <Box>
      <Typography variant="h6" p={isMobile ? "3%" : "0%"} fontWeight="bold">
        {t("identity_access_management")}
      </Typography>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {/* Public Area */}
          {seedflow && (
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom fontWeight="bold">
                  {t("public_area")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="Public Key"
                  label={t("public_key")}
                  name="Public Key"
                  value={pubKey}
                  sx={{
                    mb: { xs: "4%", sm: "1%" },
                    mt: { xs: "4%", sm: "1%" },
                    width: { xs: "100%", sm: "50%" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <CopyToClipboard text={pubKey} onCopy={handleCopy}>
                        <Tooltip
                          title={!copied ? t("copy") : t("copied")}
                          placement="top"
                        >
                          <IconButton color="primary">
                            <ContentCopyOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="Public Key URL"
                  label={t("pub_key_url")}
                  name="Public Key URL"
                  value={pbkurl}
                  onChange={(e) => setPbkurl(e.target.value)}
                  sx={{
                    width: { xs: "100%", sm: "50%" },
                  }}
                  disabled={!editable}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={editable ? handleSave : handleEdit}
                        color="primary"
                      >
                        {editable ? t("save") : t("edit")}
                      </Button>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          )}

          {/* Private Area */}
          <Grid item xs={12}>
            <Typography
              variant="body1"
              color="red"
              gutterBottom
              fontWeight="bold"
            >
              {t("private_area")}
            </Typography>
            <Grid container spacing={1}>
              {seedflow && (
                <Grid item xs={12}>
                  <Button
                    onClick={props.handleOpenPasswordExport}
                    variant="contained"
                    sx={{ width: isMobile ? "100%" : "15%" }}
                  >
                    {t("export_secret")}
                  </Button>
                </Grid>
              )}
              {seedflow && (
                <Grid item xs={12}>
                  <Button
                    onClick={props.handleOpenForget}
                    variant="contained"
                    sx={{ width: isMobile ? "100%" : "15%" }}
                  >
                    {t("forget_secret")}
                  </Button>
                </Grid>
              )}
              {canSign && !seedflow && (
                <Grid item xs={12}>
                  <Button
                    onClick={props.handleOpenPasswordGenerate}
                    variant="contained"
                    sx={{ width: isMobile ? "100%" : "15%" }}
                  >
                    {t("generate_secret")}
                  </Button>
                </Grid>
              )}
              {canSign && !seedflow && (
                <Grid item xs={12}>
                  <Button
                    onClick={props.handleOpenPasswordImport}
                    variant="contained"
                    sx={{ width: isMobile ? "100%" : "15%" }}
                  >
                    {t("import_secret")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default IAMCard;
