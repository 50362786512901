// React
import React from "react";

// Material UI Components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";

// reactflow
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";

// A ---------------------------------------------------------------------- M

const Target = ({ data }) => {
  return data ? (
    <Grid item container>
      <Paper sx={{ p: "3%" }}>
        <Typography variant="h6" align="center" gutterBottom>
          <b>Target Endpoint</b>
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Type</b>
              </TableCell>
              <TableCell>
                <b>Datatypes</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}
            >
              <TableCell>{data.target.endpoint || "N/A"}</TableCell>
              <TableCell>{data.target.type || "REST"}</TableCell>
              <TableCell>
                {data.target.types && data.target.types.length > 0
                  ? data.target.types.join(", ")
                  : "N/A"}
              </TableCell>
              <TableCell
                sx={{ color: data.target.enabled ? "#00c853" : "#b0b8b0" }}
              >
                {data.target.enabled ? "ENABLED" : "DISABLED"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Handle
        type="target"
        position={Position.Left}
        id={`handle-${data.target.endpoint}`}
      />
    </Grid>
  ) : null;
};

export default Target;
