// React
import React, { useState, useEffect } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

// Components
import ActivatedPubKeyTable from "ui-components/ResellerManagement/ActivatedPubKeyTable";
import ActivatedAPIKeyTable from "ui-components/ResellerManagement/ActivatedAPIKeyTable";
import ActivateAPIKey from "ui-components/ResellerManagement/ActivateAPIKey";
import ActivatePublicKey from "ui-components/ResellerManagement/ActivatePublicKey";
import {
  EnabledPublicKeySuccessful,
  EnabledPublicKeyError,
  EnabledAPIKeySuccessful,
  EnabledAPIKeyError,
} from "ui-components/ORFeedbacks";

// Firebase
import { db } from "config/firebase";
import { doc, collection, query, where, onSnapshot } from "firebase/firestore";

// Functions
import { genRndStringPrintable } from "SafeTwin/crypto/cryptolibsodium";
import getActivatedPublicKeys from "user/getActivatedPublicKeys";
import getActivatedAPIKeys from "user/getActivatedAPIKeys";

// A ---------------------------------------------------------------------- M

const ResellerPanel = () => {
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [publicKeysData, setPublicKeysData] = useState([]);
  const [APIKey, setAPIKey] = useState("");
  const [APIKeysData, setAPIKeysData] = useState([]);
  const [isPublicKeyDialogOpen, setPublicKeyDialogOpen] = useState(false);
  const [isApiKeyDialogOpen, setApiKeyDialogOpen] = useState(false);
  const [openPubKeySnackbarSuccessful, setOpenPubKeySnackbarSuccessful] =
    useState(false);
  const [openPubKeySnackbarError, setOpenPubKeySnackbarError] = useState(false);
  const [openAPIKeySnackbarSuccessful, setOpenAPIKeySnackbarSuccessful] =
    useState(false);
  const [openAPIKeySnackbarError, setOpenAPIKeySnackbarError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleOpenPublicKeyDialog = () => {
    setPublicKeyDialogOpen(true);
  };

  const handleClosePublicKeyDialog = () => {
    setPublicKeyDialogOpen(false);
  };

  const handleOpenApiKeyDialog = () => {
    const APIKey = genRndStringPrintable(32);
    setAPIKey(APIKey);
    setApiKeyDialogOpen(true);
  };

  const handleCloseApiKeyDialog = () => {
    setApiKeyDialogOpen(false);
  };

  useEffect(() => {
    const fetchActivatedPubKeys = async () => {
      const activatedPublicKeys = await getActivatedPublicKeys(user.uid);
      activatedPublicKeys.sort((pk1, pk2) => pk2.added_on - pk1.added_on);
      setPublicKeysData(activatedPublicKeys);
    };

    const fetchActivatedAPIKeys = async () => {
      const activatedAPIKeys = await getActivatedAPIKeys(user.uid);
      activatedAPIKeys.sort((ak1, ak2) => ak2.added_on - ak1.added_on);
      setAPIKeysData(activatedAPIKeys);
    };

    const fetchData = async () => {
      await fetchActivatedPubKeys();
      await fetchActivatedAPIKeys();
      setIsLoading(false);
    };

    fetchData();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

    const userRef = doc(db, "enabled_records", user.uid);
    const enabledPublicKeysCollectionRef = collection(
      userRef,
      "enabled_public_keys"
    );
    const enabledAPIKeysCollectionRef = collection(userRef, "enabled_api_keys");

    const newAdditionsQueryEnabledPublicKeys = query(
      enabledPublicKeysCollectionRef,
      where("added_on", ">=", currentTimestampInSeconds.toString())
    );

    const newAdditionsQueryEnabledAPIKeys = query(
      enabledAPIKeysCollectionRef,
      where("added_on", ">=", currentTimestampInSeconds.toString())
    );

    const newAdditionsUnsubscribeEnabledPublicKeys = onSnapshot(
      newAdditionsQueryEnabledPublicKeys,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            fetchActivatedPubKeys();
          }
        });
      }
    );

    const newAdditionsUnsubscribeEnabledAPIKeys = onSnapshot(
      newAdditionsQueryEnabledAPIKeys,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            fetchActivatedAPIKeys();
          }
        });
      }
    );

    return () => {
      newAdditionsUnsubscribeEnabledPublicKeys();
      newAdditionsUnsubscribeEnabledAPIKeys();
    };
  }, [user]);

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <Grid item container xs={12} spacing={5}>
        <Grid item xs={12}>
          <Typography variant={isMobile ? "h6" : "h5"} fontWeight="bold">
            {t("reseller_panel")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item container spacing={5}>
          {/* Activated API Keys Table*/}
          {APIKeysData && (
            <Grid item xs={12}>
              <Paper elevation={3}>
                <Box p={2}>
                  <ActivatedAPIKeyTable
                    APIKeysData={APIKeysData}
                    handleOpen={handleOpenApiKeyDialog}
                  />
                </Box>
              </Paper>
            </Grid>
          )}

          {/* Activated Public Keys Table*/}
          {publicKeysData && (
            <Grid item xs={12}>
              <Paper elevation={3}>
                <Box p={2}>
                  <ActivatedPubKeyTable
                    publicKeysData={publicKeysData}
                    handleOpen={handleOpenPublicKeyDialog}
                  />
                </Box>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* API Key*/}
      {APIKey && (
        <ActivateAPIKey
          APIKey={APIKey}
          open={isApiKeyDialogOpen}
          setOpen={setApiKeyDialogOpen}
          handleClose={handleCloseApiKeyDialog}
          setOpenAPIKeySnackbarSuccessful={setOpenAPIKeySnackbarSuccessful}
          setOpenAPIKeySnackbarError={setOpenAPIKeySnackbarError}
        />
      )}

      {/* Public Key*/}
      <ActivatePublicKey
        open={isPublicKeyDialogOpen}
        setOpen={setPublicKeyDialogOpen}
        handleClose={handleClosePublicKeyDialog}
        setOpenPubKeySnackbarSuccessful={setOpenPubKeySnackbarSuccessful}
        setOpenPubKeySnackbarError={setOpenPubKeySnackbarError}
      />

      {/* Public Key Successful*/}
      <EnabledPublicKeySuccessful
        open={openPubKeySnackbarSuccessful}
        setOpen={setOpenPubKeySnackbarSuccessful}
      />

      {/* Public Key Error*/}
      <EnabledPublicKeyError
        open={openPubKeySnackbarError}
        setOpen={setOpenPubKeySnackbarError}
      />

      {/* API Key Successful*/}
      <EnabledAPIKeySuccessful
        open={openAPIKeySnackbarSuccessful}
        setOpen={setOpenAPIKeySnackbarSuccessful}
      />

      {/* API Key Error*/}
      <EnabledAPIKeyError
        open={openAPIKeySnackbarError}
        setOpen={setOpenAPIKeySnackbarError}
      />
    </>
  );
};

export default ResellerPanel;
