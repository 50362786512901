// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// Functions
import getUserContacts from "user/getUserContacts";
import getDataKeyData from "datakey/getDataKeyData";

// A ---------------------------------------------------------------------- M

const fetchSignature = async (txid, isPublic) => {
  try {
    const sigRef = doc(db, "recordsdata", txid);
    const sigDoc = await getDoc(sigRef);

    if (!sigDoc.exists()) {
      console.error(`No document found with ID: ${txid}`);
      return null;
    }

    const signatureData = sigDoc.data();
    const creator_uuid = signatureData.creator_uuid || "N/A";
    let creator = "N/A";

    if (creator_uuid && creator_uuid !== "N/A") {
      try {
        const creatorContacts = await getUserContacts(creator_uuid);
        creator =
          creatorContacts.email ||
          (await getDataKeyData(creator_uuid)).name ||
          "N/A";
      } catch (error) {
        console.error("Unable to fetch creator:", error.message);
      }
    }

    return {
      id: sigDoc.id,
      creator_email: creator,
      public: isPublic,
      ...signatureData,
    };
  } catch (error) {
    console.error("Error in fetchSignature:", error.message);
    return null;
  }
};

export default fetchSignature;
