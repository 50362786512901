// React
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Context
import { UserAuth } from "context/AuthContext";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// Components
import GroupToolbar from "ui-components/GroupManagement/GroupToolbar";
import SharedTagsTable from "ui-components/SharedTagsTable";

// Firebase
import { db } from "config/firebase";
import { onSnapshot, query, where, collectionGroup } from "firebase/firestore";

// Functions
import getGroup from "group/getGroup";
import getTagsByViewGroup from "tag/getTagsByViewGroup";
import getTagsByViewGroupReadOnly from "tag/getTagsByViewGroupReadOnly";

// A ---------------------------------------------------------------------- M

const GroupDataBoxes = () => {
  const groupID = useParams().group;
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  const [group, setGroup] = useState();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const group = await getGroup(groupID);
      const tags = conservSostL1
        ? await getTagsByViewGroupReadOnly(groupID)
        : await getTagsByViewGroup(groupID);
      setGroup(group);
      setTags(tags);
    };

    fetch();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const newAdditionsQuery = conservSostL1
      ? query(
          collectionGroup(db, "viewgroups_readonly"),
          where("group_id", "==", groupID),
          where("added_on", ">", currentTimestampInSeconds.toString())
        )
      : query(
          collectionGroup(db, "viewgroups"),
          where("group_id", "==", groupID),
          where("added_on", ">", currentTimestampInSeconds.toString())
        );

    const newAdditionsUnsubscribe = onSnapshot(
      newAdditionsQuery,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            fetch();
          }
        });
      }
    );

    return () => {
      newAdditionsUnsubscribe();
    };
  }, [groupID]);

  return (
    <Grid item container xs={12} spacing={5}>
      {group && (
        <>
          <Grid item xs={12}>
            <GroupToolbar group={group} />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
          </Grid>

          <Grid item container xs={12} spacing={5}>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight="bold">
                {conservSostL1 ? t("archives") : t("databoxes")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <SharedTagsTable tags={tags} />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default GroupDataBoxes;
