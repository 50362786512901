// React
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// Components
import TagToolbar from "ui-components/DataboxManagement/TagToolbar";
import ViewGroupsTable from "ui-components/DataboxManagement/ViewGroupsTable";
import EnabledDataKeysTable from "ui-components/DataboxManagement/EnabledDataKeysTable";
import ForwardRoutesTable from "ui-components/DataboxManagement/ForwardRoutesTable";
import AddViewGroup from "ui-components/DataboxManagement/AddViewGroup";
import AddDataKey from "ui-components/DataboxManagement/AddDataKey";
import NewTargetEndpoint from "ui-components/DataboxManagement/NewTargetEndpoint";
import EditForwardRoute from "ui-components/DataboxManagement/EditForwardRoute";
import {
  ForwardRouteRemovedSuccessfulSnackbar,
  ForwardRouteRemovedErrorSnackbar,
} from "ui-components/ORFeedbacks";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// Functions
import getTag from "tag/getTag";
import getSeenTag from "user/getSeenTag";
import getViewGroups from "tag/getViewGroups";
import getViewGroupsReadOnly from "tag/getViewGroupsReadOnly";
import getAuthorizedDataKeys from "tag/getAuthorizedDataKeys";
import getForwardRoutes from "tag/getForwardRoutes";

// A ---------------------------------------------------------------------- M

const TagSettings = () => {
  const { user, conservSostL1 } = UserAuth();
  const { tag } = useParams();
  const { t } = useTranslation();

  const [seentag, setSeenTag] = useState();
  const [viewgroups, setViewGroups] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [forwardRoutes, setForwardRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState();
  const [openAddViewGroup, setOpenAddViewGroup] = useState(false);
  const [openAddDataKey, setOpenAddDataKey] = useState(false);
  const [openAddForwardRoute, setOpenAddForwardRoute] = useState(false);
  const [openEditForwardRoute, setOpenEditForwardRoute] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    openForwardRouteRemovedSuccessful,
    setOpenForwardRouteRemovedSuccessful,
  ] = useState(false);
  const [openForwardRouteRemovedError, setOpenForwardRouteRemovedError] =
    useState(false);

  useEffect(() => {
    const fetchSeenTag = async () => {
      const seentag = await getSeenTag(user.uid, tag);
      const tagData = await getTag(tag);
      const tagName = tagData.name;
      setSeenTag({ ...seentag, name: tagName });
    };

    const fetchViewGroups = async () => {
      let viewgroups;

      if (conservSostL1) {
        const [readOnlyGroups, allPermissionsGroups] = await Promise.all([
          getViewGroupsReadOnly(tag),
          getViewGroups(tag),
        ]);
        viewgroups = [...readOnlyGroups, ...allPermissionsGroups];
      } else {
        viewgroups = await getViewGroups(tag);
      }

      setViewGroups(viewgroups);
    };

    const fetchDataKeys = async () => {
      const dataKeys = await getAuthorizedDataKeys(tag);
      setDataKeys(dataKeys.authorizedKeysArray);
    };

    const fetchForwardRoutes = async () => {
      const forwardRoutes = await getForwardRoutes(tag);
      setForwardRoutes(forwardRoutes);
    };

    const fetchData = async () => {
      await fetchSeenTag();
      await fetchViewGroups();
      await fetchDataKeys();
      await fetchForwardRoutes();
      setIsLoading(false);
    };

    fetchData();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const tagsdataDocRef = doc(db, "tagsdata", tag);
    const viewgroupsCollectionRef = collection(tagsdataDocRef, "viewgroups");
    const viewgroupsReadOnlyCollectionRef = collection(
      tagsdataDocRef,
      "viewgroups_readonly"
    );
    const authorizedKeysCollectionRef = collection(
      tagsdataDocRef,
      "authorized_keys"
    );
    const forwardRoutesCollectionRef = collection(
      tagsdataDocRef,
      "forward_routes"
    );

    const newAdditionsQueryViewGroups = query(
      viewgroupsCollectionRef,
      where("added_on", ">=", currentTimestampInSeconds.toString())
    );

    const newAdditionsQueryViewGroupsReadOnly = query(
      viewgroupsReadOnlyCollectionRef,
      where("added_on", ">=", currentTimestampInSeconds.toString())
    );

    const newAdditionsQueryDataKeys = query(
      authorizedKeysCollectionRef,
      where("added_on", ">=", currentTimestampInSeconds.toString())
    );

    const newAdditionsQueryForwardRoutes = query(
      forwardRoutesCollectionRef,
      where("added_on", ">=", currentTimestampInSeconds.toString())
    );

    const newAdditionsUnsubscribeViewGroups = onSnapshot(
      newAdditionsQueryViewGroups,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            fetchViewGroups();
          }
        });
      }
    );

    const newAdditionsUnsubscribeViewGroupsReadOnly = onSnapshot(
      newAdditionsQueryViewGroupsReadOnly,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            fetchViewGroups();
          }
        });
      }
    );

    const deletionUnsubscribeViewGroups = onSnapshot(
      viewgroupsCollectionRef,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "removed") {
            fetchViewGroups();
          }
        });
      }
    );

    const deletionUnsubscribeViewGroupsReadOnly = onSnapshot(
      viewgroupsReadOnlyCollectionRef,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "removed") {
            fetchViewGroups();
          }
        });
      }
    );

    const newAdditionsUnsubscribeDataKeys = onSnapshot(
      newAdditionsQueryDataKeys,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            fetchDataKeys();
          }
        });
      }
    );

    const deletionUnsubscribeDataKeys = onSnapshot(
      authorizedKeysCollectionRef,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "removed") {
            fetchDataKeys();
          }
        });
      }
    );

    const newAdditionsUnsubscribeForwardRoutes = onSnapshot(
      newAdditionsQueryForwardRoutes,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            fetchForwardRoutes();
          }
        });
      }
    );

    const modifiyUnsubscribeForwardRoutes = onSnapshot(
      forwardRoutesCollectionRef,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "modified") {
            fetchForwardRoutes();
          }
        });
      }
    );

    const deletionUnsubscribeForwardRoutes = onSnapshot(
      forwardRoutesCollectionRef,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "removed") {
            fetchForwardRoutes();
          }
        });
      }
    );

    return () => {
      newAdditionsUnsubscribeViewGroups();
      newAdditionsUnsubscribeViewGroupsReadOnly();
      deletionUnsubscribeViewGroups();
      deletionUnsubscribeViewGroupsReadOnly();
      newAdditionsUnsubscribeDataKeys();
      deletionUnsubscribeDataKeys();
      newAdditionsUnsubscribeForwardRoutes();
      modifiyUnsubscribeForwardRoutes();
      deletionUnsubscribeForwardRoutes();
    };
  }, [tag, user]);

  const openAddViewGroupDrawer = () => {
    setOpenAddViewGroup(true);
    window.history.pushState(null, "");
  };

  const openAddDataKeyDialog = () => {
    setOpenAddDataKey(true);
    window.history.pushState(null, "");
  };

  const openAddForwardRouteDrawer = () => {
    setOpenAddForwardRoute(true);
    window.history.pushState(null, "");
  };

  const clickEdit = (route) => {
    setSelectedRoute(route);
    setOpenEditForwardRoute(true);
    window.history.pushState(null, "");
  };

  const handleOpenForwardRouteRemovedSuccessful = () => {
    setOpenForwardRouteRemovedSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenForwardRouteRemovedError = () => {
    setOpenForwardRouteRemovedError(true);
    window.history.pushState(null, "");
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid item container xs={12} spacing={5}>
      {seentag && (
        <>
          <Grid item xs={12}>
            <TagToolbar tag={seentag} />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} md={10}>
                <Typography variant="h5" fontWeight="bold">
                  {t("settings")}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container spacing={5}>
              <Grid item xs={12}>
                <Paper elevation={3}>
                  <Box p={2}>
                    <ViewGroupsTable
                      tag={seentag}
                      groups={viewgroups}
                      openAddViewGroupDrawer={openAddViewGroupDrawer}
                    />
                  </Box>
                </Paper>
              </Grid>

              {!conservSostL1 && (
                <Grid item xs={12}>
                  <Paper elevation={3}>
                    <Box p={2}>
                      <EnabledDataKeysTable
                        tag={seentag}
                        dataKeys={dataKeys}
                        openAddDataKeyDialog={openAddDataKeyDialog}
                      />
                    </Box>
                  </Paper>
                </Grid>
              )}

              {!conservSostL1 && (
                <Grid item xs={12}>
                  <Paper elevation={3}>
                    <Box p={2}>
                      <ForwardRoutesTable
                        tag={seentag}
                        forwardRoutes={forwardRoutes}
                        openAddForwardRouteDrawer={openAddForwardRouteDrawer}
                        clickEdit={clickEdit}
                        handleSuccessfulRemoved={
                          handleOpenForwardRouteRemovedSuccessful
                        }
                        handleErrorRemoved={handleOpenForwardRouteRemovedError}
                      />
                    </Box>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>

          <AddViewGroup
            tag={seentag}
            open={openAddViewGroup}
            setOpen={setOpenAddViewGroup}
          />

          <AddDataKey
            tag={seentag}
            dataKeys={dataKeys}
            open={openAddDataKey}
            setOpen={setOpenAddDataKey}
          />

          <NewTargetEndpoint
            tag={seentag}
            open={openAddForwardRoute}
            setOpen={setOpenAddForwardRoute}
          />

          {selectedRoute && (
            <EditForwardRoute
              tag={seentag}
              route={selectedRoute}
              open={openEditForwardRoute}
              setOpen={setOpenEditForwardRoute}
            />
          )}

          <ForwardRouteRemovedSuccessfulSnackbar
            open={openForwardRouteRemovedSuccessful}
            setOpen={setOpenForwardRouteRemovedSuccessful}
          />

          <ForwardRouteRemovedErrorSnackbar
            open={openForwardRouteRemovedError}
            setOpen={setOpenForwardRouteRemovedError}
          />
        </>
      )}
    </Grid>
  );
};

export default TagSettings;
