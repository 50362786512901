// React
import React, { useState, useRef } from "react";

// react-to-pdf
import generatePDF from "react-to-pdf";

// Material UI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

// Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DownloadIcon from "@mui/icons-material/Download";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const CDNFileUploadReceipt = ({
  receiptDialogOpen,
  handleResetUploadReceipt,
  uploadReceipt,
}) => {
  const targetRef = useRef();

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const handleDownloadPDF = async () => {
    setIsGeneratingPDF(true);
    await generatePDF(targetRef, {
      filename: `${uploadReceipt.txid}-receipt.pdf`,
    });
    setIsGeneratingPDF(false);
  };

  return (
    <Dialog
      open={receiptDialogOpen}
      onClose={handleResetUploadReceipt}
      fullWidth
      maxWidth="lg"
    >
      <Box sx={{ p: "2%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb="3%"
        >
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              Rapporto Di Versamento
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleResetUploadReceipt}
              sx={{ color: "red" }}
              edge="end"
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item container spacing={3} ref={targetRef}>
            {uploadReceipt.status === "valid" ? (
              <>
                <Grid item xs={12}>
                  <Paper elevation={4} sx={{ p: "2%" }}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={0.5}>
                        <CheckCircleIcon
                          sx={{ color: "green" }}
                          fontSize="large"
                        />
                      </Grid>
                      <Grid item xs={11.5}>
                        <Typography>
                          Il documento è stato versato con successo.
                          L'identificativo assegnato è:{" "}
                          <b>{uploadReceipt.txid || "N/A"}</b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={10} sx={{ p: "2%" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          color="green"
                          gutterBottom
                          fontWeight="bold"
                        >
                          Dettagli Versamento
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Effettuato da:</strong>{" "}
                          {uploadReceipt.creatorEmail || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Stato versamento:</strong>{" "}
                          {uploadReceipt.status || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Nome documento:</strong>{" "}
                          {uploadReceipt.fileName || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Tipo documento:</strong>{" "}
                          {uploadReceipt.fileType || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>
                            Datetime generazione rapporto di versamento:
                          </strong>{" "}
                          {uploadReceipt.timestamp
                            ? convertTimestamp(uploadReceipt.timestamp)
                            : "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Paper elevation={4} sx={{ p: "2%" }}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={0.5}>
                        <ErrorOutlineIcon
                          sx={{ color: "red" }}
                          fontSize="large"
                        />
                      </Grid>
                      <Grid item xs={11.5}>
                        <Typography color="error">
                          Errore nel versamento del documento.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={10} sx={{ p: "2%" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography color="error" gutterBottom>
                          Dettagli Rifiuto Di Versamento
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Versamento Effettuato Da:</strong>{" "}
                          {uploadReceipt.creatorEmail || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Stato Versamento:</strong>{" "}
                          {uploadReceipt.status || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Nome Documento Rifiutato:</strong>{" "}
                          {uploadReceipt.fileName || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Tipo Documento Rifiutato:</strong>{" "}
                          {uploadReceipt.fileType || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Datetime Rifiuto Versamento:</strong>{" "}
                          {uploadReceipt.timestamp
                            ? convertTimestamp(uploadReceipt.timestamp)
                            : "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadPDF}
              disabled={isGeneratingPDF}
            >
              {isGeneratingPDF ? "Generating PDF..." : "Export As PDF"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default CDNFileUploadReceipt;
