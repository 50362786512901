// React
import React, { useEffect } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// qrcode.react
import { QRCodeSVG } from "qrcode.react";

// Material UI Components
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// A ---------------------------------------------------------------------- M

const TagQRCode = ({ tag, open, setOpen }) => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  const handlePrint = () => {
    const printWindow = window.open("", "", "width=600,height=400");
    const qrCode = document.querySelector("#qr-code-svg");
    printWindow.document.open();
    printWindow.document.write(`
	  <html>
		<head>
		  <title>Print tag code</title>
		  <style>
			@media print {
			  body * {
				visibility: hidden;
			  }
			  #qr-code-svg,
			  #qr-code-svg * {
				visibility: visible;
			  }
			  #qr-code-svg {
				position: absolute;
				left: 250;
				top: 50;
			  }
			}
			.close-button {
				position: fixed;
				top: 20px;
				right: 20px;
				padding: 12px 24px;
				font-size: 45px;
				font-weight: bold;
				background-color: #e0e0e0;
				border: none;
				cursor: pointer;
			  }
		  </style>
		</head>
		<body>
		  <button class="close-button" onclick="window.close()">Close window</button>
		  ${qrCode.outerHTML}
		</body>
	  </html>
	`);
    printWindow.document.close();
    printWindow.print();
    setOpen(false);
  };

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <Box sx={{ p: { xs: "5%", sm: "4%" } }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("qr_code")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => setOpen(false)}
              edge="end"
              sx={{ color: "red" }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <List>
          <ListItem>
            <ListItemText primary={t("qr_code_steps_1")} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t("qr_code_steps_2")} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                conservSostL1 ? t("qr_code_steps_3") : t("qr_code_steps_4")
              }
            />
          </ListItem>
        </List>
        <Box
          sx={{ display: "flex", justifyContent: "center", mt: "5%", mb: "8%" }}
        >
          <QRCodeSVG
            id="qr-code-svg"
            style={{ height: 200, width: 200 }}
            value={`${process.env.REACT_APP_ENVIRONMENT_URL}/${tag}`}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button fullWidth variant="contained" onClick={handlePrint}>
            {t("print")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TagQRCode;
