// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import InputLabel from "@mui/material/InputLabel";
import Divider from "@mui/material/Divider";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";

// Components
import { Puller } from "ui-components/Puller";

// A ---------------------------------------------------------------------- M

const CDNFileFilterFormDialog = ({
  open,
  setOpen,
  onClose,
  onFilter,
  currentFilters,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [filterCriteria, setFilterCriteria] = useState({
    nomeFile: "",
    formatoFile: "",
    nomeFileDestinazione: "",
    cartellaDestinazione: "",
    classeDocumentale: "",
    dataDocumentoStart: "",
    dataDocumentoEnd: "",
    impronta: "",
    algoritmo: "",
    identificativoDocumento: "",
    versioneDocumento: "",
    modalitaFormazione: "",
    tipologiaFlusso: "",
    tipoRegistro: "",
    dataRegistrazioneStart: "",
    dataRegistrazioneEnd: "",
    numeroDocumento: "",
    codiceRegistro: "",
    oggetto: "",
    ruolo: "",
    tipoSoggetto: "",
    cognome: "",
    nome: "",
    denominazione: "",
    codiceFiscale: "",
    indirizziDigitaliDiRiferimento: "",
    allegatiNumero: "",
    idDocIndiceAllegati: "",
    descrizioneAllegati: "",
    indiceDiClassificazione: "",
    descrizioneClassificazione: "",
    riservato: "",
    pianoClassificazione: "",
    prodottoSoftwareNome: "",
    prodottoSoftwareVersione: "",
    prodottoSoftwareProduttore: "",
    verificaFirmaDigitale: "",
    verificaMarcaTemporale: "",
    verificaSigillo: "",
    verificaConformitaCopie: "",
    idAggregazione: "",
    identificativoDocumentoPrincipale: "",
    tracciaturaModificheTipo: "",
    soggettoAutoreModifica: "",
    tracciaturaModificheDataStart: "",
    tracciaturaModificheDataEnd: "",
    tracciaturaModificheIdDocVersionePrecedente: "",
    tempoConservazione: "",
    note: "",
  });

  useEffect(() => {
    setFilterCriteria(currentFilters);
  }, [currentFilters]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterCriteria({ ...filterCriteria, [name]: value });
  };

  const handleFilter = () => {
    onFilter(filterCriteria);
    onClose();
  };

  const CDNFilterDocumentsForm = (
    <Grid container spacing={5}>
      {/* Section: File Details */}
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" color="gray" fontWeight="bold">
            Dettagli File
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nome File"
            name="nomeFile"
            id="nomeFile"
            variant="outlined"
            fullWidth
            value={filterCriteria.nomeFile}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Formato File</InputLabel>
            <Select
              name="formatoFile"
              label="Formato File"
              value={filterCriteria.formatoFile}
              onChange={handleChange}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="application/pdf">application/pdf</MenuItem>
              <MenuItem value="image/png">image/png</MenuItem>
              <MenuItem value="image/jpg">image/jpg</MenuItem>
              <MenuItem value="image/jpeg">image/jpeg</MenuItem>
              <MenuItem value="text/xml">text/xml</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nome File Di Destinazione"
            name="nomeFileDestinazione"
            id="nomeFileDestinazione"
            variant="outlined"
            fullWidth
            value={filterCriteria.nomeFileDestinazione}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Cartella Di Destinazione"
            name="cartellaDestinazione"
            id="cartellaDestinazione"
            variant="outlined"
            fullWidth
            value={filterCriteria.cartellaDestinazione}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Classe Documentale</InputLabel>
            <Select
              name="classeDocumentale"
              label="Classe Documentale"
              value={filterCriteria.classeDocumentale}
              onChange={handleChange}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="FATTURE">FATTURE</MenuItem>
              <MenuItem value="CONTRATTI">CONTRATTI</MenuItem>
              <MenuItem value="OIL - MANDATI E REVERSALI">
                OIL - MANDATI E REVERSALI
              </MenuItem>
              <MenuItem value="ORDINI ACQUISTO NSO">
                ORDINI ACQUISTO NSO
              </MenuItem>
              <MenuItem value="LIBRI E REGISTRI CONTABILI">
                LIBRI E REGISTRI CONTABILI
              </MenuItem>
              <MenuItem value="DDT">DDT</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Data Documento Inizio"
            name="dataDocumentoStart"
            id="dataDocumentoStart"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={filterCriteria.dataDocumentoStart}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Data Documento Fine"
            name="dataDocumentoEnd"
            id="dataDocumentoEnd"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={filterCriteria.dataDocumentoEnd}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Impronta"
            name="impronta"
            id="impronta"
            variant="outlined"
            fullWidth
            value={filterCriteria.impronta}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Algoritmo"
            name="algoritmo"
            id="algoritmo"
            variant="outlined"
            fullWidth
            value={filterCriteria.algoritmo}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Identificativo Documento"
            name="identificativoDocumento"
            id="identificativoDocumento"
            variant="outlined"
            fullWidth
            value={filterCriteria.identificativoDocumento}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Versione Del Documento"
            name="versioneDocumento"
            id="versioneDocumento"
            variant="outlined"
            fullWidth
            value={filterCriteria.versioneDocumento}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      {/* Section: Formation And Registration*/}
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" color="gray" fontWeight="bold">
            Formazione e Registrazione
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Modalità Di Formazione</InputLabel>
            <Select
              label="Modalità Di Formazione"
              name="modalitaFormazione"
              value={filterCriteria.modalitaFormazione}
              onChange={handleChange}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="a">a</MenuItem>
              <MenuItem value="b">b</MenuItem>
              <MenuItem value="c">c</MenuItem>
              <MenuItem value="d">d</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Tipologia Di Flusso</InputLabel>
            <Select
              label="Tipologia Di Flusso"
              name="tipologiaFlusso"
              value={filterCriteria.tipologiaFlusso}
              onChange={handleChange}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="U">U</MenuItem>
              <MenuItem value="E">E</MenuItem>
              <MenuItem value="I">I</MenuItem>
              <MenuItem value="In uscita">In uscita</MenuItem>
              <MenuItem value="In entrata">In entrata</MenuItem>
              <MenuItem value="Interno">Interno</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Tipo Registro</InputLabel>
            <Select
              label="Tipo Registro"
              name="tipoRegistro"
              value={filterCriteria.tipoRegistro}
              onChange={handleChange}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="Nessuno">Nessuno</MenuItem>
              <MenuItem value="Protocollo Ordinario/Protocollo Emergenza">
                Protocollo Ordinario/Protocollo Emergenza
              </MenuItem>
              <MenuItem value="Repertorio/Registro">
                Repertorio/Registro
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Data Registrazione Inizio"
            name="dataRegistrazioneStart"
            id="dataRegistrazioneStart"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={filterCriteria.dataRegistrazioneStart}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Data Registrazione Fine"
            name="dataRegistrazioneEnd"
            id="dataRegistrazioneEnd"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={filterCriteria.dataRegistrazioneEnd}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Numero Documento"
            name="numeroDocumento"
            id="numeroDocumento"
            variant="outlined"
            fullWidth
            value={filterCriteria.numeroDocumento}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Codice Registro"
            name="codiceRegistro"
            id="codiceRegistro"
            variant="outlined"
            fullWidth
            value={filterCriteria.codiceRegistro}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Oggetto"
            name="oggetto"
            id="oggetto"
            variant="outlined"
            fullWidth
            value={filterCriteria.oggetto}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      {/* Section: Role and Subject */}
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" color="gray" fontWeight="bold">
            Ruolo e Soggetto
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Ruolo</InputLabel>
            <Select
              label="Ruolo"
              name="ruolo"
              value={filterCriteria.ruolo}
              onChange={handleChange}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="Assegnatario">Assegnatario</MenuItem>
              <MenuItem value="Autore">Autore</MenuItem>
              <MenuItem value="Destinatario">Destinatario</MenuItem>
              <MenuItem value="Mittente">Mittente</MenuItem>
              <MenuItem value="Operatore">Operatore</MenuItem>
              <MenuItem value="Produttore">Produttore</MenuItem>
              <MenuItem value="RGD">RGD</MenuItem>
              <MenuItem value="RSP">RSP</MenuItem>
              <MenuItem value="Soggetto che effettua la registrazione">
                Soggetto che effettua la registrazione
              </MenuItem>
              <MenuItem value="altro">altro</MenuItem>
              <MenuItem value="Amministrazione che effettua la registrazione">
                Amministrazione che effettua la registrazione
              </MenuItem>
              <MenuItem value="RUP">RUP</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Tipo Soggetto</InputLabel>
            <Select
              label="Tipo Soggetto"
              name="tipoSoggetto"
              value={filterCriteria.tipoSoggetto}
              onChange={handleChange}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="PF">PF</MenuItem>
              <MenuItem value="PG">PG</MenuItem>
              <MenuItem value="PAI">PAI</MenuItem>
              <MenuItem value="PAE">PAE</MenuItem>
              <MenuItem value="SW">SW</MenuItem>
              <MenuItem value="AS">AS</MenuItem>
              <MenuItem value="RUP">RUP</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Cognome"
            name="cognome"
            id="cognome"
            variant="outlined"
            fullWidth
            value={filterCriteria.cognome}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nome"
            name="nome"
            id="nome"
            variant="outlined"
            fullWidth
            value={filterCriteria.nome}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Denominazione"
            name="denominazione"
            id="denominazione"
            variant="outlined"
            fullWidth
            value={filterCriteria.denominazione}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Codice Fiscale"
            name="codiceFiscale"
            id="codiceFiscale"
            variant="outlined"
            fullWidth
            value={filterCriteria.codiceFiscale}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      {/* Section: Process Details */}
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" color="gray" fontWeight="bold">
            Dettagli Processo
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Indirizzi Digitali Di Riferimento"
            name="indirizziDigitaliDiRiferimento"
            id="indirizziDigitaliDiRiferimento"
            variant="outlined"
            fullWidth
            value={filterCriteria.indirizziDigitaliDiRiferimento}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Allegati Numero"
            name="allegatiNumero"
            id="allegatiNumero"
            variant="outlined"
            fullWidth
            value={filterCriteria.allegatiNumero}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="IdDoc Indice Allegati"
            name="idDocIndiceAllegati"
            id="idDocIndiceAllegati"
            variant="outlined"
            fullWidth
            value={filterCriteria.idDocIndiceAllegati}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Descrizione Allegati"
            name="descrizioneAllegati"
            id="descrizioneAllegati"
            variant="outlined"
            fullWidth
            value={filterCriteria.descrizioneAllegati}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Indice Di Classificazione"
            name="indiceDiClassificazione"
            id="indiceDiClassificazione"
            variant="outlined"
            fullWidth
            value={filterCriteria.indiceDiClassificazione}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Descrizione Classificazione"
            name="descrizioneClassificazione"
            id="descrizioneClassificazione"
            variant="outlined"
            fullWidth
            value={filterCriteria.descrizioneClassificazione}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Riservato"
            name="riservato"
            id="riservato"
            variant="outlined"
            fullWidth
            value={filterCriteria.riservato}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Piano Classificazione"
            name="pianoClassificazione"
            id="pianoClassificazione"
            variant="outlined"
            fullWidth
            value={filterCriteria.pianoClassificazione}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Prodotto Software Nome"
            name="prodottoSoftwareNome"
            id="prodottoSoftwareNome"
            variant="outlined"
            fullWidth
            value={filterCriteria.prodottoSoftwareNome}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Prodotto Software Versione"
            name="prodottoSoftwareVersione"
            id="prodottoSoftwareVersione"
            variant="outlined"
            fullWidth
            value={filterCriteria.prodottoSoftwareVersione}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Prodotto Software Produttore"
            name="prodottoSoftwareProduttore"
            id="prodottoSoftwareProduttore"
            variant="outlined"
            fullWidth
            value={filterCriteria.prodottoSoftwareProduttore}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Verifica Firma Digitale"
            name="verificaFirmaDigitale"
            id="verificaFirmaDigitale"
            variant="outlined"
            fullWidth
            value={filterCriteria.verificaFirmaDigitale}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Verifica Marca Temporale"
            name="verificaMarcaTemporale"
            id="verificaMarcaTemporale"
            variant="outlined"
            fullWidth
            value={filterCriteria.verificaMarcaTemporale}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Verifica Sigillo"
            name="verificaSigilloirmaDigitale"
            id="verificaSigillo"
            variant="outlined"
            fullWidth
            value={filterCriteria.verificaSigillo}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Verifica Conformità Copie"
            name="verificaConformitaCopie"
            id="verificaConformitaCopie"
            variant="outlined"
            fullWidth
            value={filterCriteria.verificaConformitaCopie}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Id Aggregazione"
            name="idAggregazione"
            id="idAggregazione"
            variant="outlined"
            fullWidth
            value={filterCriteria.idAggregazione}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Identificativo Documento Principale"
            name="identificativoDocumentoPrincipale"
            id="identificativoDocumentoPrincipale"
            variant="outlined"
            fullWidth
            value={filterCriteria.identificativoDocumentoPrincipale}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Tracciatura modifiche - Tipo</InputLabel>
            <Select
              label="Tracciatura Modifiche - Tipo"
              name="tracciaturaModificheTipo"
              value={filterCriteria.tracciaturaModificheTipo}
              onChange={handleChange}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="annullamento">annullamento</MenuItem>
              <MenuItem value="rettifica">rettifica</MenuItem>
              <MenuItem value="integrazione">integrazione</MenuItem>
              <MenuItem value="annotazione">annotazione</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Soggetto Autore Della Modifica"
            name="soggettoAutoreModifica"
            id="soggettoAutoreModifica"
            variant="outlined"
            fullWidth
            value={filterCriteria.soggettoAutoreModifica}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Tracciatura Modifiche - Data Inizio"
            name="tracciaturaModificheDataStart"
            id="tracciaturaModificheDataStart"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={filterCriteria.tracciaturaModificheDataStart}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Tracciatura Modifiche - Data Fine"
            name="tracciaturaModificheDataEnd"
            id="tracciaturaModificheDataEnd"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={filterCriteria.tracciaturaModificheDataEnd}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Tracciatura Modifiche - IdDoc Versione Precedente"
            name="tracciaturaModificheIdDocVersionePrecedente"
            id="tracciaturaModificheIdDocVersionePrecedente"
            variant="outlined"
            fullWidth
            value={filterCriteria.tracciaturaModificheIdDocVersionePrecedente}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Tempo Di Conservazione"
            name="tempoConservazione"
            id="tempoConservazione"
            variant="outlined"
            fullWidth
            value={filterCriteria.tempoConservazione}
            onChange={handleChange}
          />
        </Grid>

        {/* Section: Additional Information */}
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" color="gray" fontWeight="bold">
              Informazioni Aggiuntive
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Note"
              name="note"
              id="note"
              variant="outlined"
              fullWidth
              value={filterCriteria.note}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<SearchIcon />}
          onClick={handleFilter}
        >
          {t("search")}
        </Button>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "90%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          mt="5%"
          mb="10%"
        >
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("search_documents")}
            </Typography>
          </Grid>
        </Grid>
        {CDNFilterDocumentsForm}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("search_documents")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{CDNFilterDocumentsForm}</DialogContent>
    </Dialog>
  );
};

CDNFileFilterFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default CDNFileFilterFormDialog;
