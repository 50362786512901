// React
import React, { useState } from "react";

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material UI Icons
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import InfoIcon from "@mui/icons-material/Info";

// A ---------------------------------------------------------------------- M

const useStyles = makeStyles(() => ({
  input: {
    display: "none",
  },
}));

const CDNFileForm = ({
  isMobile,
  request,
  tags,
  location,
  source,
  downloadURL,
  blobURL,
  fileName,
  documentProperties,
  tagSelectionOpen,
  selectedTag,
  handleDocumentPropertyChange,
  handleFileChange,
  handleChange,
  handleOpen,
  handleClose,
  handleFileClick,
}) => {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const handleClickOpen = (content) => {
    setDialogContent(content);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogContent("");
  };

  const metadataNotes = {
    nomeFile: {
      id: "nomeFile",
      name: "Nome File",
      description:
        "Il nome del file, utilizzato esternamente, composto da caratteri alfanumerici.",
      required: true,
    },
    formatoFile: {
      id: "formatoFile",
      name: "Formato File",
      description: "Formato del file, ad esempio XML, PDF, ecc.",
      required: true,
    },
    nomeFileDestinazione: {
      id: "nomeFileDestinazione",
      name: "Nome File Di Destinazione",
      description:
        "Il nome con cui il file sarà archiviato nel sistema di gestione documentale o archivio.",
      required: true,
    },
    cartellaDestinazione: {
      id: "cartellaDestinazione",
      name: "Cartella Di Destinazione",
      description:
        "La cartella in cui verrà archiviato il file nel sistema di gestione documentale o archivio.",
      required: true,
    },
    classeDocumentale: {
      id: "classeDocumentale",
      name: "Classe Documentale",
      description:
        "Categoria del documento, come fatture, contratti, registro, ecc.",
      required: true,
    },
    dataDocumento: {
      id: "dataDocumento",
      name: "Data Documento",
      description:
        "La data di emissione del documento, espressa nel formato mm/dd/yyyy.",
      required: true,
    },
    impronta: {
      id: "impronta",
      name: "Impronta",
      description: "L'hash del documento calcolato dall'ente che lo produce.",
      required: true,
    },
    algoritmo: {
      id: "algoritmo",
      name: "Algoritmo",
      description:
        "L'algoritmo usato per l'hash, con SHA-256 come predefinito.",
      required: true,
    },
    identificativoDocumento: {
      id: "identificativoDocumento",
      name: "Identificativo Documento",
      description:
        "ID del documento, come l'identificativo SdI per fatture o nome file.",
      required: true,
    },
    versioneDocumento: {
      id: "versioneDocumento",
      name: "Versione Documento",
      description: "Numero di versione, ad esempio 1, 2, 3, ecc.",
      required: true,
    },
    modalitaFormazione: {
      id: "modalitaFormazione",
      name: "Modalità Di Formazione",
      description:
        "Modalità con cui è stato generato il documento: a) tramite software; b) acquisizione telematica o scannerizzata; c) transazioni, processi informatici o moduli; d) generato da database. Basta indicare la lettera corrispondente.",
      required: true,
    },
    tipologiaFlusso: {
      id: "tipologiaFlusso",
      name: "Tipologia Di Flusso",
      description:
        "In uscita, in entrata o interno per indicare se il flusso del documento è attivo, passivo o relativo ai registri contabili.",
      required: true,
    },
    tipoRegistro: {
      id: "tipoRegistro",
      name: "Tipo Registro",
      description:
        "Indica il tipo di registro: Nessuno, Protocollo Ordinario/Emergenza, o Repertorio/Registro.",
      required: true,
    },
    dataRegistrazione: {
      id: "dataRegistrazione",
      name: "Data Registrazione",
      description:
        "Data e ora della registrazione del documento, nel formato mm/dd/yyyy.",
      required: true,
    },
    numeroDocumento: {
      id: "numeroDocumento",
      name: "Numero Documento",
      description:
        "Il numero di registrazione del documento, ad esempio il numero di protocollo, ID SDI o un numero concatenato mese/anno.",
      required: true,
    },
    codiceRegistro: {
      id: "codiceRegistro",
      name: "Codice Registro",
      description:
        "Codice del registro in cui viene archiviato il documento, necessario se il tipo di registro è Protocollo o Repertorio.",
      required: false,
    },
    oggetto: {
      id: "oggetto",
      name: "Oggetto",
      description: "Descrizione libera, come ad esempio 'fattura attiva'.",
      required: true,
    },
    ruolo: {
      id: "ruolo",
      name: "Ruolo",
      description:
        "Ruolo del soggetto associato al documento, come autore, destinatario, operatore, o responsabile. Almeno un soggetto deve essere specificato.",
      required: true,
    },
    tipoSoggetto: {
      id: "tipoSoggetto",
      name: "Tipo Soggetto",
      description:
        "Tipologia del soggetto: PF per persona fisica, PG per organizzazione, PAI per PA italiana, PAE per PA estera, SW per documenti generati automaticamente.",
      required: true,
    },
    cognome: {
      id: "cognome",
      name: "Cognome",
      description: "Obbligatorio se il Tipo Soggetto è PF (persona fisica).",
      required: false,
    },
    nome: {
      id: "nome",
      name: "Nome",
      description: "Obbligatorio se il Tipo Soggetto è PF (persona fisica).",
      required: false,
    },
    denominazione: {
      id: "denominazione",
      name: "Denominazione",
      description:
        "Obbligatorio per PG, PAI, PAE o documenti generati automaticamente.",
      required: false,
    },
    codiceFiscale: {
      id: "codiceFiscale",
      name: "Codice Fiscale",
      description: "Codice fiscale o partita IVA del soggetto.",
      required: false,
    },
    indirizziDigitaliDiRiferimento: {
      id: "indirizziDigitaliDiRiferimento",
      name: "Indrizzi Digitali Di Riferimento",
      description: "Indirizzi digitali di riferimento, se presenti.",
      required: false,
    },
    allegatiNumero: {
      id: "allegatiNumero",
      name: "Allegati Numero",
      description:
        "Numero di allegati, come 0, 1, 2, ecc. Non include allegati incorporati nel documento principale.",
      required: true,
    },
    idDocIndiceAllegati: {
      id: "idDocIndiceAllegati",
      name: "IdDoc Indice Allegati",
      description:
        "ID da fornire per ogni allegato, se il Numero allegati è maggiore di 0.",
      required: false,
    },
    descrizioneAllegati: {
      id: "descrizioneAllegati",
      name: "Descrizione Allegati",
      description: "Descrizione libera degli allegati.",
      required: false,
    },
    indiceDiClassificazione: {
      id: "indiceDiClassificazione",
      name: "Indice Di Classificazione",
      description:
        "Codifica del documento basata sul piano di classificazione adottato.",
      required: false,
    },
    descrizioneClassificazione: {
      id: "descrizioneClassificazione",
      name: "Descrizione Classificazione",
      description: "Descrizione estesa del codice di classificazione indicato.",
      required: false,
    },
    riservato: {
      id: "riservato",
      name: "Riservato",
      description:
        "Booleano (vero/falso) che indica la visibilità nel sistema documentale.",
      required: true,
    },
    pianoClassificazione: {
      id: "pianoClassificazione",
      name: "Piano Classificazione",
      description: "URI del piano di classificazione pubblicato.",
      required: true,
    },
    prodottoSoftwareNome: {
      id: "prodottoSoftwareNome",
      name: "Prodotto Software Nome",
      description: "Nome del software utilizzato, se disponibile.",
      required: false,
    },
    prodottoSoftwareVersione: {
      id: "prodottoSoftwareVersione",
      name: "Prodotto Software Versione",
      description: "Versione del software utilizzato.",
      required: false,
    },
    prodottoSoftwareProduttore: {
      id: "prodottoSoftwareProduttore",
      name: "Prodotto Software Produttore",
      description: "Produttore del software utilizzato.",
      required: false,
    },
    verificaFirmaDigitale: {
      id: "verificaFirmaDigitale",
      name: "Verifica Firma Digitale",
      description:
        "Verifica della firma digitale, obbligatoria se la modalità di formazione è a o b.",
      required: false,
    },
    verificaMarcaTemporale: {
      id: "verificaMarcaTemporale",
      name: "Verifica Marca Temporale",
      description: "Verifica della marca temporale.",
      required: false,
    },
    verificaSigillo: {
      id: "verificaSigillo",
      name: "Verifica Sigillo",
      description: "Verifica del sigillo.",
      required: false,
    },
    verificaConformitaCopie: {
      id: "verificaConformitaCopie",
      name: "Verifica Conformità Copie",
      description: "Verifica della conformità delle copie.",
      required: false,
    },
    idAggregazione: {
      id: "idAggregazione",
      name: "Id Aggregazione",
      description:
        "Identificativo del fascicolo o serie, ad esempio 2.3.1.2020.",
      required: false,
    },
    identificativoDocumentoPrincipale: {
      id: "identificativoDocumentoPrincipale",
      name: "Identificativo Documento Principale",
      description:
        "ID univoco del documento principale, utilizzato per creare un collegamento con un allegato.",
      required: false,
    },
    tracciaturaModificheTipo: {
      id: "tracciaturaModificheTipo",
      name: "Tracciatura Modifiche - Tipo",
      description:
        "Nel caso di versioni successive, specifica il tipo di modifica: annullamento, rettifica, ecc.",
      required: false,
    },
    soggettoAutoreModifica: {
      id: "soggettoAutoreModifica",
      name: "Soggetto Autore Della Modifica",
      description:
        "Soggetto che ha eseguito la modifica, definito come Operatore nei metadati, per versioni successive.",
      required: false,
    },
    tracciaturaModificheData: {
      id: "tracciaturaModificheData",
      name: "Tracciatura Modifiche - Data",
      description: "Data e ora della modifica, per versioni successive.",
      required: false,
    },
    tracciaturaModificheIdDocVersionePrecedente: {
      id: "tracciaturaModificheIdDocVersionePrecedente",
      name: "Tracciatura Modifiche - IdDoc Versione Precedente",
      description:
        "ID della versione precedente del documento, in caso di modifica.",
      required: false,
    },
    tempoConservazione: {
      id: "tempoConservazione",
      name: "Tempo Di Conservazione",
      description: "Durata di conservazione, ad esempio 10 anni.",
      required: false,
    },
    note: {
      id: "note",
      name: "Note",
      description: "Note aggiuntive.",
      required: false,
    },
  };

  return (
    <>
      <Grid container spacing={5}>
        {location.pathname === "/" && tags && (
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel>Databox</InputLabel>
              <Select
                label="Databox"
                name="Databox"
                value={selectedTag}
                onChange={handleChange}
                open={tagSelectionOpen}
                onOpen={handleOpen}
                onClose={handleClose}
              >
                {tags.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    {tag.nickname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Section: File Upload */}
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" color="gray" fontWeight="bold">
              Carica File
            </Typography>
            <Divider />
          </Grid>
          {request ? (
            <Grid item container alignItems="center" spacing={1}>
              <Grid item xs={12} sm={1}>
                <Typography>File *</Typography>
              </Grid>
              <Grid item xs={12} sm={11}>
                <Button
                  startIcon={<FileOpenIcon />}
                  onClick={() => handleFileClick(downloadURL)}
                  variant="contained"
                  sx={{ width: isMobile ? "100%" : "30%" }}
                >
                  {fileName || "File selezionato"}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item container alignItems="center" spacing={1}>
              <Grid item xs={12} sm={1}>
                <Typography>File *</Typography>
              </Grid>
              <Grid item xs={12} sm={11}>
                <input
                  className={classes.input}
                  id="new-upload-button"
                  type="file"
                  accept="application/pdf, text/xml, application/xml, text/plain, text/csv, image/png"
                  disabled={request}
                  onChange={(e) => handleFileChange(e.target)}
                />
                <label htmlFor="new-upload-button">
                  <Button
                    startIcon={<FileUploadIcon />}
                    disabled={request}
                    variant="contained"
                    component="span"
                    sx={{ width: isMobile ? "100%" : "30%" }}
                  >
                    Seleziona File
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography>Nome File *</Typography>
              </Grid>
              {source ? (
                <Grid item xs={12} sm={11}>
                  <Button
                    startIcon={<FileOpenIcon />}
                    onClick={() => handleFileClick(blobURL)}
                    variant="contained"
                    sx={{ width: isMobile ? "100%" : "30%" }}
                  >
                    {fileName || "File selezionato"}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} sm={10.5}>
                  <Typography>Nessun file selezionato</Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>

        {source && (
          <>
            {/* Section: File Details */}
            <Grid item container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="gray" fontWeight="bold">
                  Dettagli File
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.nomeFile.name}
                  name={metadataNotes.nomeFile.id}
                  id={metadataNotes.nomeFile.id}
                  variant="outlined"
                  fullWidth
                  required={metadataNotes.nomeFile.required}
                  value={documentProperties.nomeFile}
                  sx={{ backgroundColor: "#f5f5f5" }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.nomeFile)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {documentProperties.formatoFile && (
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={metadataNotes.formatoFile.name}
                    name={metadataNotes.formatoFile.id}
                    id={metadataNotes.formatoFile.id}
                    variant="outlined"
                    fullWidth
                    required={metadataNotes.formatoFile.required}
                    value={documentProperties.formatoFile}
                    sx={{ backgroundColor: "#f5f5f5" }}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickOpen(metadataNotes.formatoFile)
                            }
                          >
                            <InfoIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.nomeFileDestinazione.name}
                  name={metadataNotes.nomeFileDestinazione.id}
                  id={metadataNotes.nomeFileDestinazione.id}
                  variant="outlined"
                  fullWidth
                  required={metadataNotes.nomeFileDestinazione.required}
                  value={documentProperties.nomeFileDestinazione}
                  sx={{ backgroundColor: "#f5f5f5" }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.nomeFileDestinazione)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.cartellaDestinazione.name}
                  name={metadataNotes.cartellaDestinazione.id}
                  id={metadataNotes.cartellaDestinazione.id}
                  variant="outlined"
                  fullWidth
                  required={metadataNotes.cartellaDestinazione.required}
                  value={documentProperties.cartellaDestinazione}
                  onChange={handleDocumentPropertyChange(
                    "cartellaDestinazione"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.cartellaDestinazione)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  required={metadataNotes.classeDocumentale.required}
                >
                  <InputLabel>
                    {metadataNotes.classeDocumentale.name}
                  </InputLabel>
                  <Select
                    name={metadataNotes.classeDocumentale.id}
                    label={metadataNotes.classeDocumentale.name}
                    value={documentProperties.classeDocumentale}
                    onChange={handleDocumentPropertyChange("classeDocumentale")}
                    input={
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() =>
                                handleClickOpen(metadataNotes.classeDocumentale)
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    }
                  >
                    <MenuItem value="FATTURE">FATTURE</MenuItem>
                    <MenuItem value="CONTRATTI">CONTRATTI</MenuItem>
                    <MenuItem value="OIL - MANDATI E REVERSALI">
                      OIL - MANDATI E REVERSALI
                    </MenuItem>
                    <MenuItem value="ORDINI ACQUISTO NSO">
                      ORDINI ACQUISTO NSO
                    </MenuItem>
                    <MenuItem value="LIBRI E REGISTRI CONTABILI">
                      LIBRI E REGISTRI CONTABILI
                    </MenuItem>
                    <MenuItem value="DDT">DDT</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.dataDocumento.name}
                  name={metadataNotes.dataDocumento.id}
                  id={metadataNotes.dataDocumento.id}
                  type="date"
                  fullWidth
                  required={metadataNotes.dataDocumento.required}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={documentProperties.dataDocumento}
                  onChange={handleDocumentPropertyChange("dataDocumento")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.dataDocumento)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {source && (
                <>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label={metadataNotes.impronta.name}
                      name={metadataNotes.impronta.id}
                      id={metadataNotes.impronta.id}
                      variant="outlined"
                      fullWidth
                      required={metadataNotes.impronta.required}
                      value={documentProperties.impronta}
                      onChange={handleDocumentPropertyChange("impronta")}
                      sx={{ backgroundColor: "#f5f5f5" }}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickOpen(metadataNotes.impronta)
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label={metadataNotes.algoritmo.name}
                      name={metadataNotes.algoritmo.id}
                      id={metadataNotes.algoritmo.id}
                      variant="outlined"
                      fullWidth
                      required={metadataNotes.algoritmo.required}
                      value={documentProperties.algoritmo}
                      onChange={handleDocumentPropertyChange("algoritmo")}
                      sx={{ backgroundColor: "#f5f5f5" }}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickOpen(metadataNotes.algoritmo)
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.identificativoDocumento.name}
                  name={metadataNotes.identificativoDocumento.id}
                  id={metadataNotes.identificativoDocumento.id}
                  variant="outlined"
                  fullWidth
                  required={metadataNotes.identificativoDocumento.required}
                  value={documentProperties.identificativoDocumento}
                  onChange={handleDocumentPropertyChange(
                    "identificativoDocumento"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.identificativoDocumento
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.versioneDocumento.name}
                  name={metadataNotes.versioneDocumento.id}
                  id={metadataNotes.versioneDocumento.id}
                  variant="outlined"
                  fullWidth
                  required={metadataNotes.versioneDocumento.required}
                  value={documentProperties.versioneDocumento}
                  onChange={handleDocumentPropertyChange("versioneDocumento")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.versioneDocumento)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {/* Section: File Formation And Registration */}
            <Grid item container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="gray" fontWeight="bold">
                  Formazione e Registrazione File
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  required={metadataNotes.modalitaFormazione.required}
                >
                  <InputLabel>
                    {metadataNotes.modalitaFormazione.name}
                  </InputLabel>
                  <Select
                    label={metadataNotes.modalitaFormazione.name}
                    name={metadataNotes.modalitaFormazione.id}
                    value={documentProperties.modalitaFormazione}
                    onChange={handleDocumentPropertyChange(
                      "modalitaFormazione"
                    )}
                    input={
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() =>
                                handleClickOpen(
                                  metadataNotes.modalitaFormazione
                                )
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    }
                  >
                    <MenuItem value="a">a</MenuItem>
                    <MenuItem value="b">b</MenuItem>
                    <MenuItem value="c">c</MenuItem>
                    <MenuItem value="d">d</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  required={metadataNotes.tipologiaFlusso.required}
                >
                  <InputLabel>{metadataNotes.tipologiaFlusso.name}</InputLabel>
                  <Select
                    label={metadataNotes.tipologiaFlusso.name}
                    name={metadataNotes.tipologiaFlusso.id}
                    value={documentProperties.tipologiaFlusso}
                    onChange={handleDocumentPropertyChange("tipologiaFlusso")}
                    input={
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() =>
                                handleClickOpen(metadataNotes.tipologiaFlusso)
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    }
                  >
                    <MenuItem value="U">U</MenuItem>
                    <MenuItem value="E">E</MenuItem>
                    <MenuItem value="I">I</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  required={metadataNotes.tipoRegistro.required}
                >
                  <InputLabel>{metadataNotes.tipoRegistro.name}</InputLabel>
                  <Select
                    label={metadataNotes.tipoRegistro.name}
                    name={metadataNotes.tipoRegistro.id}
                    value={documentProperties.tipoRegistro}
                    onChange={handleDocumentPropertyChange("tipoRegistro")}
                    input={
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() =>
                                handleClickOpen(metadataNotes.tipoRegistro)
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    }
                  >
                    <MenuItem value="Nessuno">Nessuno</MenuItem>
                    <MenuItem value="Protocollo Ordinario/Protocollo Emergenza">
                      Protocollo Ordinario/Protocollo Emergenza
                    </MenuItem>
                    <MenuItem value="Repertorio/Registro">
                      Repertorio/Registro
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.dataRegistrazione.name}
                  name={metadataNotes.dataRegistrazione.id}
                  id={metadataNotes.dataRegistrazione.id}
                  type="date"
                  fullWidth
                  required={metadataNotes.dataRegistrazione.required}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={documentProperties.dataRegistrazione}
                  onChange={handleDocumentPropertyChange("dataRegistrazione")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.dataRegistrazione)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.numeroDocumento.name}
                  name={metadataNotes.numeroDocumento.id}
                  id={metadataNotes.numeroDocumento.id}
                  variant="outlined"
                  fullWidth
                  required={metadataNotes.numeroDocumento.required}
                  value={documentProperties.numeroDocumento}
                  onChange={handleDocumentPropertyChange("numeroDocumento")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.numeroDocumento)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.codiceRegistro.name}
                  name={metadataNotes.codiceRegistro.id}
                  id={metadataNotes.codiceRegistro.id}
                  variant="outlined"
                  fullWidth
                  required={metadataNotes.codiceRegistro.required}
                  value={documentProperties.codiceRegistro}
                  onChange={handleDocumentPropertyChange("codiceRegistro")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.codiceRegistro)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.oggetto.name}
                  name={metadataNotes.oggetto.id}
                  id={metadataNotes.oggetto.id}
                  variant="outlined"
                  fullWidth
                  required={metadataNotes.oggetto.required}
                  value={documentProperties.oggetto}
                  onChange={handleDocumentPropertyChange("oggetto")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickOpen(metadataNotes.oggetto)}
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {/* Section: Role and Subject */}
            <Grid item container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="gray" fontWeight="bold">
                  Ruolo e Soggetto
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth required={metadataNotes.ruolo.required}>
                  <InputLabel>{metadataNotes.ruolo.name}</InputLabel>
                  <Select
                    label={metadataNotes.ruolo.name}
                    name={metadataNotes.ruolo.id}
                    value={documentProperties.ruolo}
                    onChange={handleDocumentPropertyChange("ruolo")}
                    input={
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() =>
                                handleClickOpen(metadataNotes.ruolo)
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    }
                  >
                    <MenuItem value="Assegnatario">Assegnatario</MenuItem>
                    <MenuItem value="Autore">Autore</MenuItem>
                    <MenuItem value="Destinatario">Destinatario</MenuItem>
                    <MenuItem value="Mittente">Mittente</MenuItem>
                    <MenuItem value="Operatore">Operatore</MenuItem>
                    <MenuItem value="Produttore">Produttore</MenuItem>
                    <MenuItem value="RGD">RGD</MenuItem>
                    <MenuItem value="RSP">RSP</MenuItem>
                    <MenuItem value="Soggetto che effettua la registrazione">
                      Soggetto che effettua la registrazione
                    </MenuItem>
                    <MenuItem value="altro">altro</MenuItem>
                    <MenuItem value="Amministrazione che effettua la registrazione">
                      Amministrazione che effettua la registrazione
                    </MenuItem>
                    <MenuItem value="RUP">RUP</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  required={metadataNotes.tipoSoggetto.required}
                >
                  <InputLabel>{metadataNotes.tipoSoggetto.name}</InputLabel>
                  <Select
                    label={metadataNotes.tipoSoggetto.name}
                    name={metadataNotes.tipoSoggetto.id}
                    value={documentProperties.tipoSoggetto}
                    onChange={handleDocumentPropertyChange("tipoSoggetto")}
                    input={
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() =>
                                handleClickOpen(metadataNotes.tipoSoggetto)
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    }
                  >
                    <MenuItem value="PF">PF</MenuItem>
                    <MenuItem value="PG">PG</MenuItem>
                    <MenuItem value="PAI">PAI</MenuItem>
                    <MenuItem value="PAE">PAE</MenuItem>
                    <MenuItem value="SW">SW</MenuItem>
                    <MenuItem value="AS">AS</MenuItem>
                    <MenuItem value="RUP">RUP</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.cognome.name}
                  name={metadataNotes.cognome.id}
                  id={metadataNotes.cognome.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.cognome}
                  onChange={handleDocumentPropertyChange("cognome")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickOpen(metadataNotes.cognome)}
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.nome.name}
                  name={metadataNotes.nome.id}
                  id={metadataNotes.nome.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.nome}
                  onChange={handleDocumentPropertyChange("nome")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickOpen(metadataNotes.nome)}
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.denominazione.name}
                  name={metadataNotes.denominazione.id}
                  id={metadataNotes.denominazione.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.denominazione}
                  onChange={handleDocumentPropertyChange("denominazione")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.denominazione)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.codiceFiscale.name}
                  name={metadataNotes.codiceFiscale.id}
                  id={metadataNotes.codiceFiscale.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.codiceFiscale}
                  onChange={handleDocumentPropertyChange("codiceFiscale")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.codiceFiscale)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {/* Section: Process Details */}
            <Grid item container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="gray" fontWeight="bold">
                  Dettagli Processo
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.indirizziDigitaliDiRiferimento.name}
                  name={metadataNotes.indirizziDigitaliDiRiferimento.id}
                  id={metadataNotes.indirizziDigitaliDiRiferimento.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.indirizziDigitaliDiRiferimento}
                  onChange={handleDocumentPropertyChange(
                    "indirizziDigitaliDiRiferimento"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.indirizziDigitaliDiRiferimento
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.allegatiNumero.name}
                  name={metadataNotes.allegatiNumero.id}
                  id={metadataNotes.allegatiNumero.id}
                  variant="outlined"
                  fullWidth
                  required={metadataNotes.allegatiNumero.required}
                  value={documentProperties.allegatiNumero}
                  onChange={handleDocumentPropertyChange("allegatiNumero")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.allegatiNumero)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.idDocIndiceAllegati.name}
                  name={metadataNotes.idDocIndiceAllegati.id}
                  id={metadataNotes.idDocIndiceAllegati.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.idDocIndiceAllegati}
                  onChange={handleDocumentPropertyChange("idDocIndiceAllegati")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.idDocIndiceAllegati)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.descrizioneAllegati.name}
                  name={metadataNotes.descrizioneAllegati.id}
                  id={metadataNotes.descrizioneAllegati.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.descrizioneAllegati}
                  onChange={handleDocumentPropertyChange("descrizioneAllegati")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.descrizioneAllegati)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.indiceDiClassificazione.name}
                  name={metadataNotes.indiceDiClassificazione.id}
                  id={metadataNotes.indiceDiClassificazione.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.indiceDiClassificazione}
                  onChange={handleDocumentPropertyChange(
                    "indiceDiClassificazione"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.indiceDiClassificazione
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.descrizioneClassificazione.name}
                  name={metadataNotes.descrizioneClassificazione.id}
                  id={metadataNotes.descrizioneClassificazione.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.descrizioneClassificazione}
                  onChange={handleDocumentPropertyChange(
                    "descrizioneClassificazione"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.descrizioneClassificazione
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.riservato.name}
                  name={metadataNotes.riservato.id}
                  id={metadataNotes.riservato.id}
                  variant="outlined"
                  fullWidth
                  required={metadataNotes.riservato.required}
                  value={documentProperties.riservato}
                  onChange={handleDocumentPropertyChange("riservato")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.riservato)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.pianoClassificazione.name}
                  name={metadataNotes.pianoClassificazione.id}
                  id={metadataNotes.pianoClassificazione.id}
                  variant="outlined"
                  fullWidth
                  required={metadataNotes.pianoClassificazione.required}
                  value={documentProperties.pianoClassificazione}
                  onChange={handleDocumentPropertyChange(
                    "pianoClassificazione"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.pianoClassificazione)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.prodottoSoftwareNome.name}
                  name={metadataNotes.prodottoSoftwareNome.id}
                  id={metadataNotes.prodottoSoftwareNome.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.prodottoSoftwareNome}
                  onChange={handleDocumentPropertyChange(
                    "prodottoSoftwareNome"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.prodottoSoftwareNome)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.prodottoSoftwareVersione.name}
                  name={metadataNotes.prodottoSoftwareVersione.id}
                  id={metadataNotes.prodottoSoftwareVersione.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.prodottoSoftwareVersione}
                  onChange={handleDocumentPropertyChange(
                    "prodottoSoftwareVersione"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.prodottoSoftwareVersione
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.prodottoSoftwareProduttore.name}
                  name={metadataNotes.prodottoSoftwareProduttore.id}
                  id={metadataNotes.prodottoSoftwareProduttore.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.prodottoSoftwareProduttore}
                  onChange={handleDocumentPropertyChange(
                    "prodottoSoftwareProduttore"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.prodottoSoftwareProduttore
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.verificaFirmaDigitale.name}
                  name={metadataNotes.verificaFirmaDigitale.id}
                  id={metadataNotes.verificaFirmaDigitale.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.verificaFirmaDigitale}
                  onChange={handleDocumentPropertyChange(
                    "verificaFirmaDigitale"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.verificaFirmaDigitale)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.verificaMarcaTemporale.name}
                  name={metadataNotes.verificaMarcaTemporale.id}
                  id={metadataNotes.verificaMarcaTemporale.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.verificaMarcaTemporale}
                  onChange={handleDocumentPropertyChange(
                    "verificaMarcaTemporale"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.verificaMarcaTemporale
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.verificaSigillo.name}
                  name={metadataNotes.verificaSigillo.id}
                  id={metadataNotes.verificaSigillo.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.verificaSigillo}
                  onChange={handleDocumentPropertyChange("verificaSigillo")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.verificaSigillo)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.verificaConformitaCopie.name}
                  name={metadataNotes.verificaConformitaCopie.id}
                  id={metadataNotes.verificaConformitaCopie.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.verificaConformitaCopie}
                  onChange={handleDocumentPropertyChange(
                    "verificaConformitaCopie"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.verificaConformitaCopie
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.idAggregazione.name}
                  name={metadataNotes.idAggregazione.id}
                  id={metadataNotes.idAggregazione.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.idAggregazione}
                  onChange={handleDocumentPropertyChange("idAggregazione")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.idAggregazione)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.identificativoDocumentoPrincipale.name}
                  name={metadataNotes.identificativoDocumentoPrincipale.id}
                  id={metadataNotes.identificativoDocumentoPrincipale.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.identificativoDocumentoPrincipale}
                  onChange={handleDocumentPropertyChange(
                    "identificativoDocumentoPrincipale"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.identificativoDocumentoPrincipale
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>
                    {metadataNotes.tracciaturaModificheTipo.name}
                  </InputLabel>
                  <Select
                    label={metadataNotes.tracciaturaModificheTipo.name}
                    name={metadataNotes.tracciaturaModificheTipo.id}
                    value={documentProperties.tracciaturaModificheTipo}
                    onChange={handleDocumentPropertyChange(
                      "tracciaturaModificheTipo"
                    )}
                    input={
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() =>
                                handleClickOpen(
                                  metadataNotes.tracciaturaModificheTipo
                                )
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    }
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="annullamento">annullamento</MenuItem>
                    <MenuItem value="rettifica">rettifica</MenuItem>
                    <MenuItem value="integrazione">integrazione</MenuItem>
                    <MenuItem value="annotazione">annotazione</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.soggettoAutoreModifica.name}
                  name={metadataNotes.soggettoAutoreModifica.id}
                  id={metadataNotes.soggettoAutoreModifica.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.soggettoAutoreModifica}
                  onChange={handleDocumentPropertyChange(
                    "soggettoAutoreModifica"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.soggettoAutoreModifica
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.tracciaturaModificheData.name}
                  name={metadataNotes.tracciaturaModificheData.id}
                  id={metadataNotes.tracciaturaModificheData.id}
                  type="date"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={documentProperties.tracciaturaModificheData}
                  onChange={handleDocumentPropertyChange(
                    "tracciaturaModificheData"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.tracciaturaModificheData
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={
                    metadataNotes.tracciaturaModificheIdDocVersionePrecedente
                      .name
                  }
                  name={
                    metadataNotes.tracciaturaModificheIdDocVersionePrecedente.id
                  }
                  id={
                    metadataNotes.tracciaturaModificheIdDocVersionePrecedente.id
                  }
                  variant="outlined"
                  fullWidth
                  value={
                    documentProperties.tracciaturaModificheIdDocVersionePrecedente
                  }
                  onChange={handleDocumentPropertyChange(
                    "tracciaturaModificheIdDocVersionePrecedente"
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              metadataNotes.tracciaturaModificheIdDocVersionePrecedente
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.tempoConservazione.name}
                  name={metadataNotes.tempoConservazione.id}
                  id={metadataNotes.tempoConservazione.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.tempoConservazione}
                  onChange={handleDocumentPropertyChange("tempoConservazione")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickOpen(metadataNotes.tempoConservazione)
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {/* Section: Additional Information */}
            <Grid item container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="gray" fontWeight="bold">
                  Informazioni Aggiuntive
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={metadataNotes.note.name}
                  name={metadataNotes.note.id}
                  id={metadataNotes.note.id}
                  variant="outlined"
                  fullWidth
                  value={documentProperties.note}
                  onChange={handleDocumentPropertyChange("note")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickOpen(metadataNotes.note)}
                        >
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{dialogContent.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Chiudi</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CDNFileForm;
