// Firebase
import { db } from "config/firebase";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Retrieves a tag and its associated targets from Firestore.
 *
 * This function first fetches the tag data from a Firestore document based on the provided `tagID`.
 * If the tag exists, it then retrieves all associated targets from a sub-collection within the tag document.
 * Each target's data is processed and added to an array. The function returns an object containing
 * the tag's data along with its associated targets.
 *
 * @param {string} tagID - The ID of the tag to retrieve from Firestore.
 * @returns {Promise<Object>} An object containing the tag's data and associated targets.
 * If the tag doesn't exist, an empty object is returned. In case of an error, it returns an empty object
 * and logs the error message to the console.
 */
const getTag = async (tagID) => {
  try {
    // Reference to the tag document in Firestore
    const tagRef = doc(db, "tagsdata", tagID);

    // Retrieve the tag document
    const tagSnapshot = await getDoc(tagRef);

    if (tagSnapshot.exists()) {
      // Extract tag data from the document
      const tagData = tagSnapshot.data();

      // Reference to the targets sub-collection in the tag document
      const targetsCollectionRef = collection(tagRef, "targets");

      // Retrieve all documents from the targets sub-collection
      const targetsSnapshot = await getDocs(targetsCollectionRef);

      const targets = [];

      // Process each target document
      targetsSnapshot.forEach((targetDoc) => {
        const targetData = targetDoc.data();
        const target = {
          name: targetData.name,
          prototype: targetData.prototype,
        };

        // Include prototype metadata if available
        if (targetData.prototype_meta) {
          target.prototype_meta = targetData.prototype_meta;
        }

        targets.push(target);
      });

      // Return the tag with its associated targets
      return {
        id: tagSnapshot.id,
        ...tagData,
        targets,
      };
    }

    // Return an empty object if the tag does not exist
    return {};
  } catch (e) {
    console.error("Error in getTag:", e.message);
    // Return an empty object in case of an error
    return {};
  }
};

export default getTag;
