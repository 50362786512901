// React
import React, { useState, useEffect, useCallback } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// Material UI Components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Components
import {
  CertificationSuccessful,
  CertificationError,
} from "ui-components/ORFeedbacks";
import CertificationsTable from "ui-components/CertificationsTable";
import DataDialog from "ui-components/DataDialog";
import DatapointIntegrityInspector from "ui-components/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// Generators
import DocGenerator from "ui-components/generators/DocGenerator";
import ImageGenerator from "ui-components/generators/ImageGenerator";
import InfoGenerator from "ui-components/generators/InfoGenerator";
import CDNFileGenerator from "ui-components/generators/CDNFileGenerator/CDNFileGenerator";

// Conservazione Sostitutiva Components
import CDNFileDetailsDialog from "ui-components/viewers/CDNFileViewer/CDNFileDetailsDialog";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// Functions
import fetchSeenTags from "user/fetchSeenTags";
import fetchSignatures from "user/fetchSignatures";
import verifySignature from "verificator/verifySignature";

// A ---------------------------------------------------------------------- M

const MyCertifications = () => {
  const { user, seedflow, canSign, conservSostL1, APIKey } = UserAuth();

  const [seentags, setSeenTags] = useState([]);
  const [signatures, setSignatures] = useState([]);
  const [record, setRecord] = useState(null);
  const [verification, setVerification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [dialogs, setDialogs] = useState({
    docGen: false,
    imageGen: false,
    infoGen: false,
    uploadGen: false,
    verify: false,
    view: false,
    certificationSuccessful: false,
    certificationError: false,
  });

  const [verificationLoading, setVerificationLoading] = useState(false);

  const toggleDialog = (dialog, state = true) => {
    setDialogs((prev) => ({ ...prev, [dialog]: state }));
    if (state) window.history.pushState(null, "");
  };

  const fetchUserData = useCallback(async () => {
    if (!user) return;

    try {
      const [seentags, signatures] = await Promise.all([
        fetchSeenTags(user.uid),
        fetchSignatures(user.uid),
      ]);

      setSeenTags(seentags.sort((a, b) => b.firstseen - a.firstseen));
      setSignatures(signatures.sort((a, b) => b.timestamp - a.timestamp));
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUserData();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const userdataDocRef = doc(db, "userdata", user?.uid);
    const mysigsCollectionRef = collection(userdataDocRef, "mysigs");

    const newAdditionsQueryCertifications = query(
      mysigsCollectionRef,
      where("timestamp", ">=", currentTimestampInSeconds.toString())
    );

    const unsubscribe = onSnapshot(
      newAdditionsQueryCertifications,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") fetchUserData();
        });
      }
    );

    return unsubscribe;
  }, [user, fetchUserData]);

  useEffect(() => {
    const handlePopState = () => {
      if (dialogs.verify) toggleDialog("verify", false);
      if (dialogs.view) toggleDialog("view", false);
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [dialogs.verify, dialogs.view]);

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);
      const verification = await verifySignature(
        user?.uid || false,
        record,
        record.type
      );
      setRecord(record);
      setVerification(verification);
      toggleDialog("verify");
    } catch (e) {
      console.error("Error in checkVerification:", e.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const handleView = (record) => {
    setRecord(record);
    toggleDialog("view");
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid item xs={12}>
      <CertificationsTable
        signatures={signatures}
        checkVerification={checkVerification}
        handleView={handleView}
        openDocGenDrawer={() => toggleDialog("docGen")}
        openImageGenDrawer={() => toggleDialog("imageGen")}
        openInfoGenDrawer={() => toggleDialog("infoGen")}
        openUploadGenDrawer={() => toggleDialog("uploadGen")}
      />

      {canSign && seedflow && (
        <>
          <DocGenerator
            tags={seentags}
            open={dialogs.docGen}
            setOpen={() => toggleDialog("docGen", false)}
            handleOpenCertificationSuccessful={() =>
              toggleDialog("certificationSuccessful")
            }
            handleOpenCertificationError={() =>
              toggleDialog("certificationError")
            }
          />

          <ImageGenerator
            tags={seentags}
            open={dialogs.imageGen}
            setOpen={() => toggleDialog("imageGen", false)}
            handleOpenCertificationSuccessful={() =>
              toggleDialog("certificationSuccessful")
            }
            handleOpenCertificationError={() =>
              toggleDialog("certificationError")
            }
          />

          <InfoGenerator
            tags={seentags}
            open={dialogs.infoGen}
            setOpen={() => toggleDialog("infoGen", false)}
            handleOpenCertificationSuccessful={() =>
              toggleDialog("certificationSuccessful")
            }
            handleOpenCertificationError={() =>
              toggleDialog("certificationError")
            }
          />
        </>
      )}

      {conservSostL1 && APIKey && (
        <CDNFileGenerator
          tags={seentags}
          open={dialogs.uploadGen}
          setOpen={() => toggleDialog("uploadGen", false)}
          handleOpenCertificationSuccessful={() =>
            toggleDialog("certificationSuccessful")
          }
          handleOpenCertificationError={() =>
            toggleDialog("certificationError")
          }
        />
      )}

      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {record &&
        (conservSostL1 ? (
          <CDNFileDetailsDialog
            record={record}
            open={dialogs.view}
            onClose={() => toggleDialog("view", false)}
          />
        ) : (
          <DataDialog
            data={{ ...record, databoxName: record.name }}
            open={dialogs.view}
            onClose={() => toggleDialog("view", false)}
          />
        ))}

      {record && verification && (
        <DatapointIntegrityInspector
          record={record}
          verification={verification}
          open={dialogs.verify}
          setOpen={() => toggleDialog("verify", false)}
        />
      )}

      <CertificationSuccessful
        open={dialogs.certificationSuccessful}
        setOpen={() => toggleDialog("certificationSuccessful", false)}
      />

      <CertificationError
        open={dialogs.certificationError}
        setOpen={() => toggleDialog("certificationError", false)}
      />
    </Grid>
  );
};

export default MyCertifications;
