// React
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// Material UI Components
import {
  Box,
  Grid,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Typography,
  Hidden,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  CloseOutlined as CloseOutlinedIcon,
} from "@mui/icons-material";

// Components
import CertificationMenu from "ui-components/CertificationMenu";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CertificationsTableHead = () => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell>{t("id")}</TableCell>
        <Hidden smDown>
          <TableCell>{t("generation_datetime")}</TableCell>
          <TableCell>{t("type")}</TableCell>
          <TableCell>{t("visibility")}</TableCell>
          <TableCell>{conservSostL1 ? t("archive") : t("databox")}</TableCell>
        </Hidden>
        <TableCell align="right">{t("actions")}</TableCell>
      </TableRow>
    </TableHead>
  );
};

const CertificationsTableToolbar = ({
  openDocGenDrawer,
  openImageGenDrawer,
  openInfoGenDrawer,
  openUploadGenDrawer,
}) => {
  const { canSign, seedflow, APIKey, conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  const showMenu = (canSign && seedflow) || (conservSostL1 && APIKey);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={10}>
        <Typography variant="h5" fontWeight="bold">
          {conservSostL1 ? t("my_documents") : t("my_certifications")}
        </Typography>
      </Grid>
      {!conservSostL1 && showMenu && (
        <Grid item container xs={12} md={2} justifyContent="flex-end">
          <CertificationMenu
            openDocGenDrawer={openDocGenDrawer}
            openImageGenDrawer={openImageGenDrawer}
            openInfoGenDrawer={openInfoGenDrawer}
            openUploadGenDrawer={openUploadGenDrawer}
          />
        </Grid>
      )}
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) =>
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {isRtl ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {isRtl ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CertificationsTable = ({
  signatures,
  handleView,
  checkVerification,
  openDocGenDrawer,
  openImageGenDrawer,
  openInfoGenDrawer,
  openUploadGenDrawer,
}) => {
  console.log(signatures);
  const { conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const truncateTxid = (txid) => {
    return txid.length > 10 ? `${txid.slice(0, 8)}...${txid.slice(-8)}` : txid;
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const handleCloseDialogImage = () => setSelectedImage(null);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - signatures.length) : 0;

  return (
    <Box>
      <CertificationsTableToolbar
        openDocGenDrawer={openDocGenDrawer}
        openImageGenDrawer={openImageGenDrawer}
        openInfoGenDrawer={openInfoGenDrawer}
        openUploadGenDrawer={openUploadGenDrawer}
      />
      <br />
      <Divider />
      <TableContainer>
        <Table>
          <CertificationsTableHead />
          <TableBody>
            {(rowsPerPage > 0
              ? signatures.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : signatures
            ).map((signature) => {
              const typeMap = {
                img: t("image"),
                doc: t("document"),
                info: t("note"),
                CDNFile: t("file"),
              };

              const type = typeMap[signature.type] || t("custom");

              return (
                <TableRow
                  key={signature.id}
                  sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}
                >
                  <TableCell>
                    {isMobile ? truncateTxid(signature.id) : signature.id}
                  </TableCell>
                  <Hidden smDown>
                    <TableCell>
                      {signature.timestamp
                        ? convertTimestamp(signature.timestamp)
                        : "N/A"}
                    </TableCell>
                    <TableCell>{type}</TableCell>
                    <TableCell>
                      {signature.public ? t("public") : t("private")}
                    </TableCell>
                    <TableCell>
                      {signature.tdr ? (
                        <Link
                          to={`/${signature.tdr}`}
                          style={{ textDecoration: "none" }}
                        >
                          {signature.name}
                        </Link>
                      ) : (
                        "No Databox"
                      )}
                    </TableCell>
                  </Hidden>
                  <TableCell align="right" size="small">
                    <Button onClick={() => handleView(signature)}>
                      {t("details")}
                    </Button>
                    {!conservSostL1 && (
                      <Button onClick={() => checkVerification(signature)}>
                        {t("verify")}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={6}
                  count={signatures.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) =>
                    t("displayed_rows", {
                      from,
                      to,
                      count: count !== -1 ? count : `more than ${to}`,
                    })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TablePaginationActions
              count={signatures.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          </Box>
        )}
      </TableContainer>

      {selectedFile && (
        <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog}>
          <IconButton
            sx={{
              color: "red",
              justifyContent: "flex-end",
            }}
            onClick={handleCloseDialog}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Document
            file={selectedFile}
            onLoadSuccess={handleDocumentLoadSuccess}
          >
            <Page
              pageNumber={currentPage}
              width="550"
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              {currentPage} / {numPages}
            </Typography>
            <IconButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === numPages}
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </Box>
        </Dialog>
      )}

      <Dialog open={Boolean(selectedImage)} onClose={handleCloseDialogImage}>
        <>
          <IconButton
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              zIndex: 1,
              color: "red",
            }}
            onClick={handleCloseDialogImage}
          >
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
          <img
            src={selectedImage}
            alt="img"
            style={{ width: "100%", height: "100%" }}
          />
        </>
      </Dialog>
    </Box>
  );
};

CertificationsTable.propTypes = {
  signatures: PropTypes.array.isRequired,
  handleView: PropTypes.func.isRequired,
  checkVerification: PropTypes.func.isRequired,
  openDocGenDrawer: PropTypes.func.isRequired,
  openImageGenDrawer: PropTypes.func.isRequired,
  openInfoGenDrawer: PropTypes.func.isRequired,
  openUploadGenDrawer: PropTypes.func.isRequired,
};

export default CertificationsTable;
