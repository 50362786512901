// React
import React, { useState, useEffect, useCallback } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// Material UI Components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Components
import { DataboxCreationSuccessfulSnackbar } from "ui-components/ORFeedbacks";
import DataBoxCreate from "ui-components/DataboxManagement/DataBoxCreate";
import DataBoxesTable from "ui-components/DataboxManagement/DataBoxesTable";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// Functions
import fetchSeenTags from "user/fetchSeenTags";
import getTagInfo from "tag/getTagInfo";

// A ---------------------------------------------------------------------- M

const MyDataboxes = () => {
  const { user, canCreateDatabox, conservSostL1 } = UserAuth();

  const [seentags, setSeenTags] = useState([]);
  const [openGen, setOpenGen] = useState(false);
  const [openDataboxCreationSuccessful, setOpenDataboxCreationSuccessful] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getSeenTagsWithNames = useCallback(async (userUid) => {
    try {
      const seentags = await fetchSeenTags(userUid);
      const seentagsWithNames = await Promise.all(
        seentags.map(async (tag) => {
          const tagInfo = await getTagInfo(tag.id);
          return { ...tag, name: tagInfo?.name || "N/A" };
        })
      );

      seentagsWithNames.sort((tag1, tag2) => tag2.firstseen - tag1.firstseen);
      setSeenTags(seentagsWithNames);
    } catch (error) {
      console.error("Error fetching seen tags with names:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user) {
      getSeenTagsWithNames(user.uid);

      const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
      const userdataDocRef = doc(db, "userdata", user.uid);
      const seentagsCollectionRef = collection(userdataDocRef, "seentags");

      const newAdditionsQueryTags = query(
        seentagsCollectionRef,
        where("firstseen", ">=", currentTimestampInSeconds.toString())
      );

      const unsubscribeTags = onSnapshot(newAdditionsQueryTags, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            getSeenTagsWithNames(user.uid);
          }
        });
      });

      return () => {
        unsubscribeTags();
      };
    }
  }, [user, getSeenTagsWithNames]);

  const openTagGen = () => {
    setOpenGen(true);
    window.history.pushState(null, "");
  };

  const handleOpenDataboxCreationSuccessful = () => {
    setOpenDataboxCreationSuccessful(true);
    window.history.pushState(null, "");
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid item xs={12}>
      <DataBoxesTable tags={seentags} handleOpen={openTagGen} />

      {(canCreateDatabox || conservSostL1) && (
        <>
          <DataBoxCreate
            open={openGen}
            setOpen={setOpenGen}
            handleSuccessful={handleOpenDataboxCreationSuccessful}
          />

          <DataboxCreationSuccessfulSnackbar
            open={openDataboxCreationSuccessful}
            setOpen={setOpenDataboxCreationSuccessful}
          />
        </>
      )}
    </Grid>
  );
};

export default MyDataboxes;
