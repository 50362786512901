// React
import React, { useState, useEffect } from "react";

// Context
import { UserAuth } from "context/AuthContext";

// Material UI
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Components
import HistoryConfig from "./HistoryConfig";
import DatapointIntegrityInspector from "ui-components/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// Functions
import fetchRecordsByType from "record/fetchRecordsByType";
import verifySignature from "verificator/verifySignature";

// A ---------------------------------------------------------------------- M

const ConfigViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();

  const [records, setRecords] = useState();
  const [selectedDeviceID, setSelectedDeviceID] = useState("");
  const [record, setRecord] = useState();
  const [verification, setVerification] = useState();
  const [open, setOpen] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [open]);

  useEffect(() => {
    const tagsdataDocRef = doc(db, "tagsdata", tag.id);
    const signaturesCollectionRef = collection(tagsdataDocRef, "signatures");
    const currentTimestampInSecondsString = (Date.now() / 1000).toString();

    const newAdditionsQuery = query(
      signaturesCollectionRef,
      where("type", "==", "cfg"),
      where("timestamp", ">", currentTimestampInSecondsString)
    );

    const newAdditionsUnsubscribe = onSnapshot(
      newAdditionsQuery,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            getRecords();
          }
        });
      }
    );

    const getRecords = async () => {
      const records = await fetchRecordsByType(tag.id, "cfg", isTagGroupMember);
      records.sort((a, b) => b.data.timestamp - a.data.timestamp);
      const groupedRecords = records.reduce((acc, record) => {
        const target = record.data.target;
        if (!acc[target]) {
          acc[target] = {
            deviceID: target,
            configurations: [],
          };
        }
        acc[target].configurations.push(record);
        return acc;
      }, {});

      const recordsArray = Object.values(groupedRecords);
      setRecords(recordsArray);
    };

    getRecords();

    return () => {
      newAdditionsUnsubscribe();
    };
  }, [tag, isTagGroupMember]);

  const handleOpen = () => {
    setOpen(true);
    window.history.pushState(null, "");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);

      let verification;

      if (user) {
        verification = await verifySignature(user.uid, record, record.type);
      } else {
        verification = await verifySignature(false, record, record.type);
      }

      setRecord(record);
      setVerification(verification);
      handleOpen();
    } catch (e) {
      console.error("Error in checkVerification:", e.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  return (
    <>
      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {records && records.length !== 0 && (
        <Grid container>
          <Grid item xs={12} mb="0.5%">
            <Select
              value={selectedDeviceID}
              onChange={(event) => setSelectedDeviceID(event.target.value)}
              displayEmpty
              fullWidth
              variant="outlined"
              inputProps={{ "aria-label": "Select Device ID" }}
            >
              <MenuItem value="" disabled>
                Select Target
              </MenuItem>
              {records.map((device) => (
                <MenuItem key={device.deviceID} value={device.deviceID}>
                  {device.deviceID}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            {selectedDeviceID && (
              <HistoryConfig
                records={
                  records.find((device) => device.deviceID === selectedDeviceID)
                    ?.configurations || []
                }
                checkVerification={checkVerification}
              />
            )}
          </Grid>
        </Grid>
      )}

      {verification && (
        <DatapointIntegrityInspector
          verification={verification}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

export default ConfigViewer;
