// React
import React, { useState, useEffect, useCallback, useRef } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

// Material UI Icons
import HistoryIcon from "@mui/icons-material/History";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";

// Components
import CDNFileFilterFormDialog from "./CDNFileFilterFormDialog";
import CDNFileTable from "./CDNFileTable";
import CDNPreservationProofDialog from "./CDNPreservationProofDialog";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// Functions
import fetchCDNFileRecords from "record/CDNFileRecords/fetchCDNFileRecords";

// A ---------------------------------------------------------------------- M

const initialFilters = {
  nomeFile: "",
  formatoFile: "",
  nomeFileDestinazione: "",
  cartellaDestinazione: "",
  classeDocumentale: "",
  dataDocumentoStart: "",
  dataDocumentoEnd: "",
  impronta: "",
  algoritmo: "",
  identificativoDocumento: "",
  versioneDocumento: "",
  modalitaFormazione: "",
  tipologiaFlusso: "",
  tipoRegistro: "",
  dataRegistrazioneStart: "",
  dataRegistrazioneEnd: "",
  numeroDocumento: "",
  codiceRegistro: "",
  oggetto: "",
  ruolo: "",
  tipoSoggetto: "",
  cognome: "",
  nome: "",
  denominazione: "",
  codiceFiscale: "",
  indirizziDigitaliDiRiferimento: "",
  allegatiNumero: "",
  idDocIndiceAllegati: "",
  descrizioneAllegati: "",
  indiceDiClassificazione: "",
  descrizioneClassificazione: "",
  riservato: "",
  pianoClassificazione: "",
  prodottoSoftwareNome: "",
  prodottoSoftwareVersione: "",
  prodottoSoftwareProduttore: "",
  verificaFirmaDigitale: "",
  verificaMarcaTemporale: "",
  verificaSigillo: "",
  verificaConformitaCopie: "",
  idAggregazione: "",
  identificativoDocumentoPrincipale: "",
  tracciaturaModificheTipo: "",
  soggettoAutoreModifica: "",
  tracciaturaModificheDataStart: "",
  tracciaturaModificheDataEnd: "",
  tracciaturaModificheIdDocVersionePrecedente: "",
  tempoConservazione: "",
  note: "",
};

const CDNFileViewer = ({ tag, isTagGroupMember, openUploadGenDrawer }) => {
  const { t } = useTranslation();

  const [CDNFileRecords, setCDNFileRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [isRecent, setIsRecent] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [isPreservationProofDialogOpen, setIsPreservationProofDialogOpen] =
    useState(false);
  const [record, setRecord] = useState();

  const filterLabels = {
    nomeFile: "Nome File",
    formatoFile: "Formato File",
    nomeFileDestinazione: "Nome File Di Destinazione",
    cartellaDestinazione: "Cartella Di Destinazione",
    classeDocumentale: "Classe Documentale",
    dataDocumentoStart: "Data Documento Inizio",
    dataDocumentoEnd: "Data Documento Fine",
    impronta: "Impronta",
    algoritmo: "Algoritmo",
    identificativoDocumento: "Identificativo Documento",
    versioneDocumento: "Versione Del Documento",
    modalitaFormazione: "Modalità Di Formazione",
    tipologiaFlusso: "Tipologia di Flusso",
    tipoRegistro: "Tipo Registro",
    dataRegistrazioneStart: "Data Registrazione Inizio",
    dataRegistrazioneEnd: "Data Registrazione Fine",
    numeroDocumento: "Numero Documento",
    codiceRegistro: "Codice Registro",
    oggetto: "Oggetto",
    ruolo: "Ruolo",
    tipoSoggetto: "Tipo Soggetto",
    cognome: "Cognome",
    nome: "Nome",
    denominazione: "Denominazione",
    codiceFiscale: "Codice Fiscale",
    indirizziDigitaliDiRiferimento: "Indirizzi Digitali Di Riferimento",
    allegatiNumero: "Allegati Numero",
    idDocIndiceAllegati: "IdDoc Indice Allegati",
    descrizioneAllegati: "Descrizione Allegati",
    indiceDiClassificazione: "Indice Di Classificazione",
    descrizioneClassificazione: "Descrizione Classificazione",
    riservato: "Riservato",
    pianoClassificazione: "Piano Classificazione",
    prodottoSoftwareNome: "Prodotto Software Nome",
    prodottoSoftwareVersione: "Prodotto Software Versione",
    prodottoSoftwareProduttore: "Prodotto Software Produttore",
    verificaFirmaDigitale: "Verifica Firma Digitale",
    verificaMarcaTemporale: "Verifica Marca Temporale",
    verificaSigillo: "Verifica Sigillo",
    verificaConformitaCopie: "Verifica Conformità Copie",
    idAggregazione: "Id Aggregazione",
    identificativoDocumentoPrincipale: "Identificativo Documento Principale",
    tracciaturaModificheTipo: "Tracciatura Modifiche - Tipo",
    soggettoAutoreModifica: "Soggetto Autore della Modifica",
    tracciaturaModificheDataStart: "Tracciatura Modifiche - Data Inizio",
    tracciaturaModificheDataEnd: "Tracciatura Modifiche - Data Fine",
    tempoConservazione: "Tempo Di Conservazione",
    tracciaturaModificheIdDocVersionePrecedente:
      "Tracciatura Modifiche - IdDoc Versione Precedente",
    note: "Note",
  };

  const isRecentRef = useRef(isRecent);

  useEffect(() => {
    isRecentRef.current = isRecent;
  }, [isRecent]);

  const getCDNFileRecords = useCallback(
    async (isRecentFlag = false, criteria = {}) => {
      setLoading(true);
      setError(null);

      try {
        const records = await fetchCDNFileRecords(
          tag.id,
          isTagGroupMember,
          criteria,
          isRecentFlag
        );

        if (records) {
          records.sort((a, b) => b.data.timestamp - a.data.timestamp);
          setCDNFileRecords(records);
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    const tagsdataDocRef = doc(db, "tagsdata", tag.id);
    const signaturesCollectionRef = collection(tagsdataDocRef, "signatures");
    const currentTimestampInSecondsString = (Date.now() / 1000).toString();

    const newAdditionsQuery = query(
      signaturesCollectionRef,
      where("type", "==", "CDNFile"),
      where("timestamp", ">", currentTimestampInSecondsString)
    );

    const newAdditionsUnsubscribe = onSnapshot(
      newAdditionsQuery,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added" && isRecentRef.current) {
            getCDNFileRecords(true);
          }
        });
      }
    );

    getCDNFileRecords(true);

    return () => {
      newAdditionsUnsubscribe();
    };
  }, []);

  const handleSearch = () => {
    if (!isRecent) {
      setIsRecent(true);
      setSelectedFilters(initialFilters);
      getCDNFileRecords(true);
    }
  };

  const handleFilter = (criteria) => {
    setIsRecent(false);
    setSelectedFilters(criteria);
    getCDNFileRecords(false, criteria);
  };

  const handleOpenFilterDialog = () => {
    setIsFilterDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setIsFilterDialogOpen(false);
  };

  const handleResetFilters = () => {
    setSelectedFilters(initialFilters);
    setIsRecent(true);
    getCDNFileRecords(true);
  };

  const handleOpenPreservationProofDialog = (record) => {
    setRecord(record);
    setIsPreservationProofDialogOpen(true);
  };

  const handleClosePreservationProofDialog = () => {
    setIsPreservationProofDialogOpen(false);
  };

  const filtersAreActive = () => {
    return Object.keys(selectedFilters).some(
      (key) => selectedFilters[key] !== initialFilters[key]
    );
  };

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <>Error: {error}</>;
  }

  return (
    <>
      <Grid container spacing={3}>
        {/* CDN Toolbar */}
        <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
          <Grid item xs={12} sm={12} md={2} lg={1.6}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSearch}
              startIcon={<HistoryIcon />}
            >
              View Recents
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={1.6}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleOpenFilterDialog}
              startIcon={<SearchIcon />}
            >
              Search Documents
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={openUploadGenDrawer}
              startIcon={<AddIcon />}
            >
              {t("upload_document")}
            </Button>
          </Grid>
        </Grid>

        {/* Selected Filter */}
        {filtersAreActive() && (
          <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
            <Typography variant="subtitle1" fontWeight="bold" mr="1%">
              Filtri selezionati:
            </Typography>
            {Object.keys(selectedFilters).map(
              (key) =>
                selectedFilters[key] && (
                  <Chip
                    key={key}
                    label={`${filterLabels[key]}: ${selectedFilters[key]}`}
                  />
                )
            )}
            <Grid item container xs={12} justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={handleResetFilters}
                startIcon={<RefreshIcon />}
                size="small"
              >
                Reset Filters
              </Button>
            </Grid>
          </Grid>
        )}

        {/* Documents Table */}
        {CDNFileRecords && CDNFileRecords.length > 0 ? (
          <Grid item xs={12}>
            <CDNFileTable
              records={{ CDNFileRecords }}
              handleOpenPreservationProofDialog={
                handleOpenPreservationProofDialog
              }
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <CDNFileTable records={[]} />
          </Grid>
        )}
      </Grid>

      {/* Filters Dialog */}
      <CDNFileFilterFormDialog
        open={isFilterDialogOpen}
        onClose={handleCloseFilterDialog}
        onFilter={handleFilter}
        currentFilters={selectedFilters}
      />

      {/* Preservation Proof Dialog */}
      {record && (
        <CDNPreservationProofDialog
          open={isPreservationProofDialogOpen}
          setOpen={setIsPreservationProofDialogOpen}
          onClose={handleClosePreservationProofDialog}
          record={record}
        />
      )}
    </>
  );
};

export default CDNFileViewer;
