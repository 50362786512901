// React
import React, { useState } from "react";

// Material UI Components
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

// Material UI Icons
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

// Components
import DefaultSidebar from "./DefaultSidebar";

// A ---------------------------------------------------------------------- M

const DefaultLayout = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [mobileOpen, setMobileOpen] = useState(false);

  const drawerWidth = 250;

  const appBarStyle = {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const gridItemStyle = isMobile
    ? { width: "100%", p: "1%" }
    : {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        p: "1%",
      };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AppBar position="fixed" sx={appBarStyle}>
            <Toolbar>
              {isMobile && (
                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
          </AppBar>
          <Toolbar />
        </Grid>
        <Grid item sx={gridItemStyle}>
          {props.children}
        </Grid>
      </Grid>

      <DefaultSidebar
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        drawerOpen={mobileOpen}
      />
    </>
  );
};

export default DefaultLayout;
