// React
import React, { useState, useEffect } from "react";

// Material UI Components
import { Grid } from "@mui/material";

// Components
import ClientsTable from "ui-components/CustomerManagement/ClientsTable";
import ClientFormDialog from "ui-components/CustomerManagement/ClientFormDialog";

// Firebase
import { db } from "config/firebase";
import {
  collection,
  onSnapshot,
  getDocs,
  addDoc,
  query,
  where,
  doc,
  setDoc,
} from "firebase/firestore";

// Functions
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsCollection = collection(db, "clientsdata");
        const clientsSnapshot = await getDocs(clientsCollection);
        const clientsList = clientsSnapshot.docs
          .filter((doc) => doc.id !== "defaultClient")
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        setClients(clientsList);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const clientsCollectionRef = collection(db, "clientsdata");

    const newAdditionsQueryClients = query(
      clientsCollectionRef,
      where("generationTimestamp", ">=", currentTimestampInSeconds.toString())
    );

    const unsubscribeClients = onSnapshot(
      newAdditionsQueryClients,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            fetchClients();
          }
        });
      }
    );

    return () => {
      unsubscribeClients();
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateClient = async (newClientData) => {
    try {
      // Step 1: Fetch agreements from defaultClient
      const defaultClientRef = collection(
        db,
        "clientsdata",
        "defaultClient",
        "agreements"
      );
      const defaultAgreementsSnap = await getDocs(defaultClientRef);

      const defaultAgreements = defaultAgreementsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Step 2: Add the new client to the clientsdata collection
      const clientsCollection = collection(db, "clientsdata");
      const newClientRef = await addDoc(clientsCollection, {
        ...newClientData,
        generationTimestamp: unixTimestampInSeconds(),
      });

      // Step 3: Add the inherited agreements to the new client's agreements subcollection with the same document IDs
      for (const agreement of defaultAgreements) {
        const newClientAgreementDocRef = doc(
          newClientRef,
          "agreements",
          agreement.id
        );
        await setDoc(newClientAgreementDocRef, {
          ...agreement,
        });
      }

      handleClose();
    } catch (error) {
      console.error("Error adding client:", error);
    }
  };

  return (
    <Grid item xs={12}>
      {clients && <ClientsTable clients={clients} handleOpen={handleOpen} />}

      <ClientFormDialog
        open={open}
        handleClose={handleClose}
        handleCreateClient={handleCreateClient}
      />
    </Grid>
  );
};

export default ClientList;
