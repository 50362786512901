// React
import React, { useState, useEffect } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// Components
import AccountCard from "ui-components/AccountManagement/AccountCard";
import IAMCard from "ui-components/AccountManagement/IAMCard";
import DataKeysTable from "ui-components/AccountManagement/DataKeysTable";
import NewDataKey from "ui-components/AccountManagement/NewDataKey";
import InsertPassword from "ui-components/AccountManagement/InsertPassword";
import SecretAction from "ui-components/AccountManagement/SecretActions";
import ExportSecret from "ui-components/AccountManagement/ExportMnemonic";
import AccountStatus from "ui-components/AccountManagement/AccountStatus";
import {
  RemovedKeySnackbar,
  ImportedKeySnackbar,
  NewDataKeySuccessful,
  NewDataKeyError,
} from "ui-components/ORFeedbacks";

// Firebase
import { db } from "config/firebase";
import { onSnapshot, query, where, collection } from "firebase/firestore";

// Functions
import { seedFlow } from "SafeTwin/3S/seedFlow";
import { forgetMnemonic } from "SafeTwin/3S/forgetMnemonic";
import { loadMnemonic } from "SafeTwin/3S/loadMnemonic";
import { getMnemonic } from "SafeTwin/3S/getMnemonic";
import { generateMnemonic } from "SafeTwin/crypto/bip39/bip39";
import { genRndStringPrintable } from "SafeTwin/crypto/cryptolibsodium";
import getDataKeysByOwner from "datakey/getDataKeysByOwner";

// A ---------------------------------------------------------------------- M

const AccountSettings = () => {
  const { user, setSeedFlow, checkPassword, setPubKey, conservSostL1 } =
    UserAuth();
  const { t } = useTranslation();

  const [dataKeys, setDataKeys] = useState([]);
  const [dataKey, setDataKey] = useState("");
  const [passwordExport, setPasswordExport] = useState("");
  const [passwordForget, setPasswordForget] = useState("");
  const [passwordGenerate, setPasswordGenerate] = useState("");
  const [passwordImport, setPasswordImport] = useState("");
  const [mnemonicExport, setMnemonicExport] = useState("");
  const [mnemonicImport, setMnemonicImport] = useState("");
  const [mnemonicGenerate, setMnemonicGenerate] = useState("");
  const [openForget, setOpenForget] = useState(false);
  const [openPasswordImport, setOpenPasswordImport] = useState(false);
  const [openPasswordExport, setOpenPasswordExport] = useState(false);
  const [openMnemonicForm, setOpenMnemonicForm] = useState(false);
  const [openMnemonicExport, setOpenMnemonicExport] = useState(false);
  const [openPasswordGenerate, setOpenPasswordGenerate] = useState(false);
  const [openMnemonicGenerate, setOpenMnemonicGenerate] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataKeyDialogOpen, setDataKeyDialogOpen] = useState(false);
  const [openDataKeySnackbarSuccessful, setOpenDataKeySnackbarSuccessful] =
    useState(false);
  const [openDataKeySnackbarError, setOpenDataKeySnackbarError] =
    useState(false);
  const [
    openRemovedSecretSnackbarSuccessful,
    setOpenRemovedSecretSnackbarSuccessful,
  ] = useState(false);
  const [
    openImportedSecretSnackbarSuccessful,
    setOpenImportedSecretSnackbarSuccessful,
  ] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (openPasswordExport) {
        handleClosePasswordExport();
      }

      if (openMnemonicExport) {
        handleCloseMnemonicExport();
      }

      if (openForget) {
        handleCloseForget();
      }

      if (openPasswordImport) {
        handleClosePasswordImport();
      }

      if (openMnemonicForm) {
        handleCloseMnemonicForm();
      }

      if (openPasswordGenerate) {
        handleClosePasswordGenerate();
      }

      if (openMnemonicGenerate) {
        handleCloseMnemonicGenerate();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [
    openPasswordExport,
    openMnemonicExport,
    openForget,
    openPasswordImport,
    openMnemonicForm,
    openPasswordGenerate,
    openMnemonicGenerate,
  ]);

  useEffect(() => {
    const getDataKeys = async () => {
      if (user) {
        const dataKeys = await getDataKeysByOwner(user.uid);
        dataKeys.sort((key1, key2) => key2.created_on - key1.created_on);
        setDataKeys(dataKeys);
      }
    };

    const fetchData = async () => {
      await getDataKeys();
      setIsLoading(false);
    };

    fetchData();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

    const newAdditionsQueryDataKeys = query(
      collection(db, "keydata"),
      where("owner_uuid", "==", user.uid),
      where("created_on", ">=", currentTimestampInSeconds.toString())
    );

    const newAdditionsUnsubscribeDataKeys = onSnapshot(
      newAdditionsQueryDataKeys,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            getDataKeys();
          }
        });
      }
    );

    return () => {
      newAdditionsUnsubscribeDataKeys();
    };
  }, [user]);

  const handleOpenPasswordExport = () => {
    setOpenPasswordExport(true);
    window.history.pushState(null, "");
  };

  const handleExportKey = async () => {
    const check = await checkPassword(passwordExport);
    if (check) {
      setInvalidPassword(false);
      setOpenPasswordExport(false);
      handleOpenMnemonicExport();
      const mnemonic = await getMnemonic(user, passwordExport);
      setMnemonicExport(mnemonic);
      setPasswordExport("");
    } else {
      setPasswordExport("");
      setInvalidPassword(true);
    }
  };

  const handleClosePasswordExport = () => {
    setPasswordExport("");
    setInvalidPassword(false);
    setOpenPasswordExport(false);
  };

  const handleOpenForget = () => {
    setOpenForget(true);
    window.history.pushState(null, "");
  };

  const handleForgetKey = async () => {
    const check = await checkPassword(passwordForget);
    if (check) {
      setInvalidPassword(false);
      setLoading(true);
      const public_key = await forgetMnemonic(user);
      if (public_key) {
        setPubKey("");
      }
      setLoading(false);
      setSeedFlow(false);
      setPasswordForget("");
      setOpenForget(false);
      setOpenRemovedSecretSnackbarSuccessful(true);
    } else {
      setPasswordForget("");
      setInvalidPassword(true);
    }
  };

  const handleCloseForget = () => {
    setPasswordForget("");
    setInvalidPassword(false);
    setOpenForget(false);
  };

  const handleOpenPasswordGenerate = () => {
    setOpenPasswordGenerate(true);
    window.history.pushState(null, "");
  };

  const handleGenerateKey = async () => {
    const check = await checkPassword(passwordGenerate);
    if (check) {
      setInvalidPassword(false);
      setOpenPasswordGenerate(false);
      handleOpenMnemonicGenerate();
      const mnemonic = generateMnemonic();
      setMnemonicGenerate(mnemonic);
    } else {
      setPasswordGenerate("");
      setInvalidPassword(true);
    }
  };

  const handleClosePasswordGenerate = () => {
    setPasswordGenerate("");
    setInvalidPassword(false);
    setOpenPasswordGenerate(false);
  };

  const handleOpenPasswordImport = () => {
    setOpenPasswordImport(true);
    window.history.pushState(null, "");
  };

  const handleClosePasswordImport = () => {
    setPasswordImport("");
    setInvalidPassword(false);
    setOpenPasswordImport(false);
  };

  const handleOpenMnemonicForm = async () => {
    const check = await checkPassword(passwordImport);
    if (check) {
      setInvalidPassword(false);
      setOpenPasswordImport(false);
      handleOpenMnemonicFormDrawer();
    } else {
      setPasswordImport("");
      setInvalidPassword(true);
    }
  };

  const handleOpenMnemonicFormDrawer = () => {
    setOpenMnemonicForm(true);
    window.history.pushState(null, "");
  };

  const handleLoadMnemonic = async () => {
    setLoading(true);
    const public_key = await loadMnemonic(user, passwordImport, mnemonicImport);
    if (public_key) {
      setPubKey(public_key);
    }
    const outcome = await seedFlow(user, passwordImport);
    setSeedFlow(outcome);
    setLoading(false);
    setOpenImportedSecretSnackbarSuccessful(true);
    handleCloseMnemonicForm();
  };

  const handleCloseMnemonicForm = () => {
    setPasswordImport("");
    setMnemonicImport("");
    setOpenMnemonicForm(false);
  };

  const handleOpenMnemonicExport = () => {
    setOpenMnemonicExport(true);
    window.history.pushState(null, "");
  };

  const handleCloseMnemonicExport = () => {
    setMnemonicExport("");
    setCopied(false);
    setOpenMnemonicExport(false);
  };

  const handleOpenMnemonicGenerate = () => {
    setOpenMnemonicGenerate(true);
    window.history.pushState(null, "");
  };

  const handleLoadMnemonicGenerate = async () => {
    setLoading(true);
    const public_key = await loadMnemonic(
      user,
      passwordGenerate,
      mnemonicGenerate
    );
    if (public_key) {
      setPubKey(public_key);
    }
    const outcome = await seedFlow(user, passwordGenerate);
    setSeedFlow(outcome);
    setLoading(false);
    setOpenImportedSecretSnackbarSuccessful(true);
    handleCloseMnemonicGenerate();
  };

  const handleCloseMnemonicGenerate = () => {
    setPasswordGenerate("");
    setMnemonicGenerate("");
    setOpenMnemonicGenerate(false);
  };

  const handleCopySuccess = () => {
    setCopied(true);
  };

  const handleCloseDataKeyDialog = () => {
    setDataKeyDialogOpen(false);
  };

  const handleOpenDataKeyDialog = () => {
    const dataKey = genRndStringPrintable(20);
    setDataKey(dataKey);
    setDataKeyDialogOpen(true);
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid item container xs={12} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom fontWeight="bold">
          {t("my_account")}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      {/* Section 1: Account Information */}
      <Grid item xs={12}>
        <Paper elevation={3}>
          <Box p={2}>
            <AccountCard />
          </Box>
        </Paper>
      </Grid>

      {/* Section 2: Account Status or IAMCard */}
      {conservSostL1 ? (
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box p={2}>
              <AccountStatus />
            </Box>
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box p={2}>
              <IAMCard
                handleOpenPasswordExport={handleOpenPasswordExport}
                handleOpenForget={handleOpenForget}
                handleOpenPasswordGenerate={handleOpenPasswordGenerate}
                handleOpenPasswordImport={handleOpenPasswordImport}
              />
            </Box>
          </Paper>
        </Grid>
      )}

      {/* Section 3: Data Keys */}
      {!conservSostL1 && (
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box p={2}>
              <DataKeysTable
                dataKeys={dataKeys}
                handleOpen={handleOpenDataKeyDialog}
              />
            </Box>
          </Paper>
        </Grid>
      )}

      {/* Data Key */}
      <NewDataKey
        dataKey={dataKey}
        open={isDataKeyDialogOpen}
        handleClose={handleCloseDataKeyDialog}
        setOpenDataKeySnackbarSuccessful={setOpenDataKeySnackbarSuccessful}
        setOpenDataKeySnackbarError={setOpenDataKeySnackbarError}
      />

      {/* Data Key Successful */}
      <NewDataKeySuccessful
        open={openDataKeySnackbarSuccessful}
        setOpen={setOpenDataKeySnackbarSuccessful}
      />

      {/* Data Key Error */}
      <NewDataKeyError
        open={openDataKeySnackbarError}
        setOpen={setOpenDataKeySnackbarError}
      />

      {/* Removed Secret Successful */}
      <RemovedKeySnackbar
        open={openRemovedSecretSnackbarSuccessful}
        setOpen={setOpenRemovedSecretSnackbarSuccessful}
      />

      {/* Imported Secret Successful */}
      <ImportedKeySnackbar
        open={openImportedSecretSnackbarSuccessful}
        setOpen={setOpenImportedSecretSnackbarSuccessful}
      />

      {/* Insert Password - Forget */}
      <InsertPassword
        text={t("insert_password")}
        action={t("forget_secret")}
        label={"Forget"}
        open={openForget}
        setOpen={setOpenForget}
        handleClose={handleCloseForget}
        password={passwordForget}
        setPassword={setPasswordForget}
        handleAction={handleForgetKey}
        invalidPassword={invalidPassword}
      />

      {/* Insert Password - Import */}
      <InsertPassword
        text={t("insert_password")}
        action={t("import_secret")}
        label={"Import"}
        open={openPasswordImport}
        setOpen={setOpenPasswordImport}
        handleClose={handleClosePasswordImport}
        password={passwordImport}
        setPassword={setPasswordImport}
        handleAction={handleOpenMnemonicForm}
        invalidPassword={invalidPassword}
      />

      {/* Insert Password - Export */}
      <InsertPassword
        text={t("insert_password")}
        action={t("export_secret")}
        label={"Export"}
        open={openPasswordExport}
        setOpen={setOpenPasswordExport}
        handleClose={handleClosePasswordExport}
        password={passwordExport}
        setPassword={setPasswordExport}
        handleAction={handleExportKey}
        invalidPassword={invalidPassword}
      />

      {/* Insert Password - Generate */}
      <InsertPassword
        text={t("insert_password")}
        action={t("generate_secret")}
        label={"Generate"}
        open={openPasswordGenerate}
        setOpen={setOpenPasswordGenerate}
        handleClose={handleClosePasswordGenerate}
        password={passwordGenerate}
        setPassword={setPasswordGenerate}
        handleAction={handleGenerateKey}
        invalidPassword={invalidPassword}
      />

      {/* Secret Action - Import */}
      <SecretAction
        action={t("import_secret")}
        label={"Import"}
        open={openMnemonicForm}
        setOpen={setOpenMnemonicForm}
        handleClose={handleCloseMnemonicForm}
        mnemonic={mnemonicImport}
        setMnemonic={setMnemonicImport}
        handleAction={handleLoadMnemonic}
        loading={loading}
      />

      {/* Secret Action - Generate */}
      <SecretAction
        action={t("generate_secret")}
        label={"Generate"}
        open={openMnemonicGenerate}
        setOpen={setOpenMnemonicGenerate}
        handleClose={handleCloseMnemonicGenerate}
        mnemonic={mnemonicGenerate}
        setMnemonic={setMnemonicGenerate}
        handleAction={handleLoadMnemonicGenerate}
        loading={loading}
      />

      {/* Export Secret */}
      <ExportSecret
        open={openMnemonicExport}
        setOpen={setOpenMnemonicExport}
        handleClose={handleCloseMnemonicExport}
        mnemonic={mnemonicExport}
        setMnemonic={setMnemonicExport}
        copied={copied}
        handleCopySuccess={handleCopySuccess}
      />
    </Grid>
  );
};

export default AccountSettings;
