// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  Task as TaskIcon,
} from "@mui/icons-material";

// Components
import CDNFileDetailsDialog from "./CDNFileDetailsDialog";

// Functions
import convertTimestamp from "utils/convertTimestamp";
import downloadFile from "storage/downloadFile";

// A ---------------------------------------------------------------------- M

const CDNFileTableHead = () => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell>{t("id")}</TableCell>
        <Hidden smDown>
          <TableCell>{t("generation_datetime")}</TableCell>
          <TableCell>{t("filename")}</TableCell>
          <TableCell>{t("document_class")}</TableCell>
        </Hidden>
        <TableCell align="right">{t("preservation_proof")}</TableCell>
        <TableCell align="right">{t("actions")}</TableCell>
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) =>
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {isRtl ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {isRtl ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CDNFileTable = ({ records, handleOpenPreservationProofDialog }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const truncateID = (id) => {
    return id.length > 10 ? `${id.slice(0, 8)}...${id.slice(-8)}` : id;
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDetails = (record) => {
    setSelectedRecord(record);
    setDetailsDialogOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsDialogOpen(false);
    setSelectedRecord(null);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0;

  return (
    <Box>
      <TableContainer>
        <Table>
          <CDNFileTableHead />
          <TableBody>
            {(rowsPerPage > 0
              ? records.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : records
            ).map((record) => {
              return (
                <TableRow
                  key={record.id}
                  sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}
                >
                  <TableCell>
                    {isMobile ? truncateID(record.id) : record.id}
                  </TableCell>
                  <Hidden smDown>
                    <TableCell>
                      {convertTimestamp(record.timestamp) || "N/A"}
                    </TableCell>
                    <TableCell
                      sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {record.fileName || "N/A"}
                    </TableCell>
                    <TableCell
                      sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {record.classeDocumentale || "N/A"}
                    </TableCell>
                  </Hidden>
                  <TableCell align="center" size="small">
                    <IconButton
                      onClick={() => handleOpenPreservationProofDialog(record)}
                    >
                      <TaskIcon fontSize="large" sx={{ color: "green" }} />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right" size="small">
                    <Button onClick={() => handleOpenDetails(record)}>
                      {t("details")}
                    </Button>
                    <Button
                      onClick={() =>
                        downloadFile(record.data.attachment, record.fileName)
                      }
                    >
                      {t("download")}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={7}
                  count={records.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) =>
                    t("displayed_rows", {
                      from,
                      to,
                      count: count !== -1 ? count : `more than ${to}`,
                    })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TablePaginationActions
              count={records.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          </Box>
        )}
      </TableContainer>

      {selectedRecord && (
        <CDNFileDetailsDialog
          open={detailsDialogOpen}
          setOpen={setDetailsDialogOpen}
          onClose={handleCloseDetails}
          record={selectedRecord}
        />
      )}
    </Box>
  );
};

export default CDNFileTable;
