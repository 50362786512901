// React
import React from "react";
import { Route, Routes, useParams } from "react-router-dom";

// Layout Switcher
import LayoutSwitcher from "ui-components/LayoutSwitcher";

// Route Pages
import ArmilisRoute from "pages/ArmilisRoute";
import ProtectedRoute from "pages/ProtectedRoute";
import Signin from "pages/Signin";
import ResetPassword from "pages/ResetPassword";
import NewUser from "pages/NewUser";
import MyDataboxes from "pages/MyDataboxes";
import MyCertifications from "pages/MyCertifications";
import MyGroups from "pages/MyGroups";
import AccountSettings from "pages/AccountSettings";
import Tag from "pages/Tag";
import PublicTag from "pages/PublicTag";
import TagSettings from "pages/TagSettings";
import FlowMonitor from "pages/FlowMonitor";
import GroupMembers from "pages/GroupMembers";
import GroupDataBoxes from "pages/GroupDataBoxes";
import KeyPairGenerator from "pages/KeyPairGenerator";
import ResellerPanel from "pages/ResellerPanel";
import SystemConsumption from "pages/SystemConsumption";
import SimpleCertification from "pages/quicksign/SimpleCertification";
import FileCertificationRequest from "pages/quicksign/FileCertificationRequest";
import ErrorBoundary from "pages/ErrorBoundary";
import ClientList from "pages/ClientList";
import ClientSettings from "pages/ClientSettings";

// A ---------------------------------------------------------------------- M

const LayoutSwitcherWithTag = (props) => {
  const { tag } = useParams();
  return <LayoutSwitcher tag={tag} {...props} />;
};

const App = () => {
  return (
    <Routes>
      {/* Sign In */}
      <Route
        path="/signin"
        element={
          <LayoutSwitcher>
            <Signin />
          </LayoutSwitcher>
        }
      />

      {/* Reset Password */}
      <Route
        path="/reset-password"
        element={
          <LayoutSwitcher>
            <ResetPassword />
          </LayoutSwitcher>
        }
      />

      {/* Add New User */}
      <Route
        path="/new-user"
        element={
          <ProtectedRoute>
            <LayoutSwitcher>
              <NewUser />
            </LayoutSwitcher>
          </ProtectedRoute>
        }
      />

      {/* My Databoxes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <LayoutSwitcher>
              <MyDataboxes />
            </LayoutSwitcher>
          </ProtectedRoute>
        }
      />

      {/* My Certifications */}
      <Route
        path="/my-certifications"
        element={
          <ProtectedRoute>
            <LayoutSwitcher>
              <MyCertifications />
            </LayoutSwitcher>
          </ProtectedRoute>
        }
      />

      {/* My Groups */}
      <Route
        path="/my-groups"
        element={
          <ProtectedRoute>
            <LayoutSwitcher>
              <MyGroups />
            </LayoutSwitcher>
          </ProtectedRoute>
        }
      />

      {/* My Account */}
      <Route
        path="/my-account"
        element={
          <ProtectedRoute>
            <LayoutSwitcher>
              <AccountSettings />
            </LayoutSwitcher>
          </ProtectedRoute>
        }
      />

      {/* Databox */}
      <Route
        path="/:tag"
        element={
          <ProtectedRoute>
            <LayoutSwitcher>
              <Tag />
            </LayoutSwitcher>
          </ProtectedRoute>
        }
      />

      {/* Public Databox */}
      <Route
        path="/:tag/public"
        element={
          <LayoutSwitcherWithTag>
            <PublicTag />
          </LayoutSwitcherWithTag>
        }
      />

      {/* Databox Settings */}
      <Route
        path="/:tag/settings"
        element={
          <ProtectedRoute>
            <LayoutSwitcher>
              <TagSettings />
            </LayoutSwitcher>
          </ProtectedRoute>
        }
      />

      {/* Databox Monitor */}
      <Route
        path="/:tag/monitor"
        element={
          <ProtectedRoute>
            <LayoutSwitcher>
              <FlowMonitor />
            </LayoutSwitcher>
          </ProtectedRoute>
        }
      />

      {/* Group Databoxes */}
      <Route
        path="/:group/databoxes"
        element={
          <ProtectedRoute>
            <LayoutSwitcher>
              <GroupDataBoxes />
            </LayoutSwitcher>
          </ProtectedRoute>
        }
      />

      {/* Group Members */}
      <Route
        path="/:group/members"
        element={
          <ProtectedRoute>
            <LayoutSwitcher>
              <GroupMembers />
            </LayoutSwitcher>
          </ProtectedRoute>
        }
      />

      {/* Keypair Generator */}
      <Route
        path="/keypair-generator"
        element={
          <LayoutSwitcher>
            <KeyPairGenerator />
          </LayoutSwitcher>
        }
      />

      {/* Reseller Panel */}
      <Route
        path="/reseller-panel"
        element={
          <ProtectedRoute>
            <LayoutSwitcher>
              <ResellerPanel />
            </LayoutSwitcher>
          </ProtectedRoute>
        }
      />

      {/* System Level Panel (armilis-internal-only) */}
      <Route
        path="/system-consumption"
        element={
          <ArmilisRoute>
            <LayoutSwitcher>
              <SystemConsumption />
            </LayoutSwitcher>
          </ArmilisRoute>
        }
      />

      {/* QuickSign Simple Certification */}
      <Route
        path="/quicksign/simple-cert"
        element={
          <ErrorBoundary>
            <LayoutSwitcher>
              <SimpleCertification />
            </LayoutSwitcher>
          </ErrorBoundary>
        }
      />

      {/* QuickSign File Certification Request */}
      <Route
        path="/quicksign/file-cert-req"
        element={
          <ErrorBoundary>
            <LayoutSwitcher>
              <FileCertificationRequest />
            </LayoutSwitcher>
          </ErrorBoundary>
        }
      />

      {/* Client List (armilis-internal-only) */}
      <Route
        path="/customers"
        element={
          <ArmilisRoute>
            <LayoutSwitcher>
              <ClientList />
            </LayoutSwitcher>
          </ArmilisRoute>
        }
      />

      {/* Client Settings (armilis-internal-only) */}
      <Route
        path="/customers/:customer"
        element={
          <ArmilisRoute>
            <LayoutSwitcher>
              <ClientSettings />
            </LayoutSwitcher>
          </ArmilisRoute>
        }
      />
    </Routes>
  );
};

export default App;
