// React
import React, { useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

// Material UI Icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Functions
import {
  generateMnemonic,
  mnemonicToEntropy,
} from "SafeTwin/crypto/bip39/bip39";
import {
  toHex,
  fromHex,
  toBase64,
  generateSeedEd25519KeyPair,
} from "SafeTwin/crypto/cryptolibsodium";

// A ---------------------------------------------------------------------- M

const KeyPairGenerator = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [privateKey, setPrivateKey] = useState("");
  const [publicKey, setPublicKey] = useState("");

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const generateKeyPair = () => {
    // Generate mnemonic
    const mnemonic = generateMnemonic();

    // Derive entropy from mnemonic
    const seed = mnemonicToEntropy(mnemonic);

    // Generate keypair
    const keypair = generateSeedEd25519KeyPair(fromHex(seed));

    // Private key
    const privateKeyByteArray = keypair.sk;
    const privateKeyBase64 = toBase64(privateKeyByteArray);

    // Public key
    const publicKeyByteArray = keypair.pk;
    const publicKeyHex = toHex(publicKeyByteArray);

    setPrivateKey(privateKeyBase64);
    setPublicKey(publicKeyHex);
  };

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          mt: isMobile ? 10 : 20,
          p: isMobile ? 2 : 4,
          boxShadow: 4,
          borderRadius: 2,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant={isMobile ? "h6" : "h5"}
          fontWeight="bold"
          mb="5%"
          align="center"
        >
          {t("generate_keypair")}
        </Typography>
        <Typography mb="5%">
          {t("gen_key_1")}
          <br />
          1. <b>{t("private_key")}</b>: {t("gen_key_2")}
          <br />
          2. <b>{t("public_key")}</b>: {t("gen_key_3")}
        </Typography>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              required
              fullWidth
              id="Private Key"
              label={t("private_key")}
              name="Private Key"
              value={privateKey}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => handleCopyToClipboard(privateKey)}>
                    <ContentCopyIcon />
                  </IconButton>
                ),
              }}
              aria-label={t("private_key")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              required
              fullWidth
              id="Public Key"
              label={t("public_key")}
              name="Public Key"
              value={publicKey}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => handleCopyToClipboard(publicKey)}>
                    <ContentCopyIcon />
                  </IconButton>
                ),
              }}
              aria-label={t("public_key")}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={generateKeyPair}
              aria-label={t("generate")}
              sx={{ py: 1.5 }}
            >
              {t("generate")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default KeyPairGenerator;
