// React
import React from "react";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";

// ChartJS
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

// A ---------------------------------------------------------------------- M

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Source Data",
    },
  },
  scales: {
    x: {
      type: "time",
    },
    y: {
      suggestedMin: 0,
    },
  },
};

const MpsChart = ({ chartData }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Line
      options={options}
      data={chartData}
      height={isMobile ? "300vh" : "100vh"}
    />
  );
};

export default MpsChart;
