// React
import React from "react";

// Material UI Component
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

// reactflow
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";

// A ---------------------------------------------------------------------- M

const handleStyleC = { top: 35 };
const handleStyleD = { top: 180 };

const Databox = ({ data }) => {
  return (
    <Paper sx={{ p: "30%" }}>
      {/* Databox Information */}
      <Typography variant="h6" align="center" gutterBottom>
        <b>{data.databoxName}</b>
      </Typography>
      <Handle
        type="target"
        position={Position.Left}
        style={handleStyleC}
        id="c"
      />
      <Handle
        type="target"
        position={Position.Left}
        style={handleStyleD}
        id="d"
      />
      <Handle type="source" position={Position.Right} id="e" />
    </Paper>
  );
};

export default Databox;
