import { db } from "config/firebase";
import { doc, collection, getCountFromServer } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getDataboxRecordsCount = async (tag) => {
  try {
    const tagsdataDocRef = doc(db, "tagsdata", tag);
    const tagRecordsCollectionRef = collection(tagsdataDocRef, "signatures");
    const snapshot = await getCountFromServer(tagRecordsCollectionRef);
    const count = snapshot.data().count;
    return count;
  } catch (e) {
    console.error("Error in getDataboxRecordsCount:", e.message);
    return null;
  }
};

export default getDataboxRecordsCount;
