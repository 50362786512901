// Firebase
import { db } from "config/firebase";
import { doc, setDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Creates a databox in the Firestore database under 'tagsdata' collection.
 *
 * @param {string} tagID - The identifier for the databox, used as the document key in the database.
 * @param {string} uuid - The UUID of the databox owner.
 * @param {string} name - The databox name,
 * @param {string} [documentClass] - The optional document class (e.g., FATTURE or CONTRATTI) if conservSostL1 is true.
 * @returns {Promise<void>} A promise that resolves if the operation is successful, and rejects if an error occurs.
 */
const createDataBox = async (tagID, uuid, name, documentClass) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);

    const dataBox = {
      owner_uuid: uuid,
      name: name,
      types: [],
      followers: [],
      ...(documentClass && { documentClass }),
    };

    await setDoc(tagRef, dataBox);
  } catch (e) {
    console.error("Error in createDataBox:", e.message);
  }
};

export default createDataBox;
